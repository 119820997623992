// Make component for this
.heading-wrapper {
    position: relative;
    margin-top: 100px;
    padding-top: 100px;

    &__pattern-img {
        width: 100%;
        height: 255px;
        position: absolute;
        object-fit: contain;
        left: 50%;
        transform: translateX(-50%);
        top: -50px;
    }

    h1 {
        color: var(--white);
        text-align: center;
        background-color: var(--black);
        position: relative;
        z-index: 5;
        display: inline-block;
        margin-top: 0;

        @include mq($from: tablet) {
            margin-top: 40px;
        }
    }
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: center;
    @include mq($from: tablet) {
        text-align: left;
    }
}

.text-right {
    text-align: center;
    @include mq($from: tablet) {
        text-align: right;
    }
}
