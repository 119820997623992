.card {
    width: 100%;
    border-radius: var(--card-radius);
    margin: 40px 0;
    transition: box-shadow .5s;

    &:hover,
    &.active {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
        0 2px 2px rgba(0, 0, 0, 0.12),
        0 4px 4px rgba(0, 0, 0, 0.12),
        0 8px 8px rgba(0, 0, 0, 0.12),
        0 16px 16px rgba(0, 0, 0, 0.12);
    }

    &.round {
        border-radius: $border-radius;
    }

    &.shade {
        &.blue {
            background-color: var(--blue);

            h1, h2, h3, p {
                color: var(--white)
            }
        }

        &.yellow {
            background-color: var(--yellow);
        }
    }

    .image-holder {
        width: 100%;
        height: 200px;
        background: var(--gray-3);
        border-top-left-radius: var(--card-radius);
        overflow: hidden;
        transform: translateZ(0);

        img {
            position: relative;
            width: 110%;
            top: -20%;
        }
    }

    .card-padding {
        padding: 40px;
    }

    .card-info-holder {
        padding: var(--info-paddings);
    }

    &-info-white,
    &-info-blue {
        display: flex;
        flex-direction: column;
        border-radius: $border-radius;
        padding: 24px;

        @include mq($from: 760px) {
            padding: 48px 56px;
        }

        &__title {
            margin: 0;
        }

        &__description {
            line-height: 2;
            margin: 16px 0 0;
        }

        .more-info {
            margin-top: 13px;
        }
    }

    &-info-white, &-info-white-filter {
        background: var(--white);
        box-shadow: $box-shadow;

        &__header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            a {
                font-size: rem(14px);
                cursor: pointer;
                font-weight: 600;

                &:hover {
                    color: var(--blue);
                    border-bottom: solid 1px var(--blue);
                }
            }
        }

        &__title {
            font-size: 32px;
            line-height: 34px;
        }

        &__description {
            line-height: 2;
        }

        &__button {
            display: inline-block;
            background-image: var(--gradient-blue);
            padding: 11px 0;
            border-radius: 42px;
            border: none;
            cursor: pointer;
            transition: box-shadow .3s;
            color: var(--white);
            text-decoration: none;
            text-align: center;
            max-width: 248px;
            font-size: rem(14px);
            font-weight: 800;
            margin-top: 32px;

            &:hover {
                box-shadow: 0 0 20px var(--purple);
            }
        }

        h3 {
            font-size: 20px;
            margin-bottom: 10px;
            margin-top: 0;

            &:first-of-type {
                margin-top: 20px;
            }
        }

        &__list {
            list-style: none;
            padding: 0;
            margin: 0;

            &:first-of-type {
                padding-bottom: 40px;
                border-bottom: 2px solid var(--gray-1);
            }

            li {
                a {
                    font-size: rem(18px);
                    line-height: 2.2;
                    cursor: pointer;

                    &:active, &:target, &.active {
                        font-weight: bold;
                        color: var(--blue);
                    }

                    &:hover {
                        color: var(--blue);
                        font-weight: bold;
                        border-bottom: solid 1px var(--blue);
                    }
                }
            }
        }

        span {
            border-bottom: 1px solid var(--gray-1);
            margin: 0 40px;

            li {
                a {
                    font-size: rem(18px);
                    line-height: 2.2;
                    cursor: pointer;

                    &:active, &:target, &:hover {
                        font-weight: bold;
                        color: var(--blue);
                    }
                }
            }
        }
    }

    &-info-blue {
        background: linear-gradient(90deg, #00B4E5, #CF10D9, #00B4E5, #CF10D9);
        background-size: 800% 100%;
        display: block;
        cursor: pointer;

        &:hover {
            animation: gradient 10s ease forwards infinite;

            .more-info__link__arrow {
                animation-duration: 0.5s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: ease-in-out;
                animation-name: arrow-animation;
            }
        }

        h1, h2, h3, p, div, .more-info, svg path, a {
            color: var(--white);
            fill: var(--white);
        }

        &__title {
            font-size: rem(20px);
            line-height: 24px;
        }

    }

    &-image-info,
    &-info {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 40px 24px;
        max-width: var(--max-width, 100%);
        background: var(--white);
        border-radius: $border-radius;
        text-decoration: none;
        transition: box-shadow .5s;
        box-shadow: 18px 18px 50px 0 rgb(0 0 0 / 4%);
        cursor: pointer;
        position: relative;

        &:hover {
            box-shadow: $box-shadow;

            .more-info__link__arrow {
                animation-duration: 0.5s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: ease-in-out;
                animation-name: arrow-animation;
            }
        }

        &__description {
            margin: 0 24px 24px 24px;
            line-height: 2;

            @include mq($from: 1100px) {
                margin: 0 85px 24px 85px;
            }

            .darkmode & {
                color: var(--white);
            }
        }

        .more-info {
            margin: 0 0 0 24px;
        }

        .darkmode & {
            background: var(--gray-4);
        }

    }

    &-image-info {
        padding-bottom: 24px;
        cursor: pointer;

        @include mq($from: 960px) {
            height: 542px;
        }

        a {
            text-decoration: none;
        }

        &.blog {
            height: auto;
        }

        &__image {
            border-radius: $border-radius $border-radius 0 0;
            height: 400px;
            object-fit: cover;
            object-position: top;
            width: 100%;
        }

        &__title {
            text-decoration: none;
            margin: 24px;
            font-size: rem(20px);
            line-height: 24px;

            .darkmode & {
                color: var(--white);
            }
        }

        &__description {
            line-height: 2;
            margin: 0 24px 24px 24px;
        }

    }

    &-info {
        padding-bottom: 24px;
        cursor: pointer;

        @include mq($from: 960px) {
            height: 410px;
        }

        @include mq($from: 1350px) {
            margin: 40px 24px;
        }

        &:hover {
            box-shadow: unset;
            background: linear-gradient(90deg, #00B4E5, #CF10D9, #00B4E5, #CF10D9);
            background-size: 800% 100%;
            animation: gradient 10s ease 0.8s forwards infinite;
            cursor: pointer;

            h1, h2, h3, p, div, .more-info, svg path, a {
                color: var(--white);
                fill: var(--white);
            }

            .more-info__link__arrow {
                animation-duration: 0.5s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: ease-in-out;
                animation-name: arrow-animation;
            }
        }

        &__title {
            margin: 24px;
            font-size: rem(24px);
            line-height: 28px;

            @include mq($from: 960px) {
                font-size: rem(30px);
                line-height: 34px;
            }

            @include mq($from: 1350px) {
                font-size: rem(54px);
                line-height: 54px;
                margin: 85px 85px 24px 85px;
            }

            .darkmode & {
                color: var(--white);
            }
        }

        &__description {
            margin: 0 24px 24px 24px;
            line-height: 2;

            @include mq($from: 1350px) {
                margin: 0 85px 24px 85px;
            }

            .darkmode & {
                color: var(--white);
            }
        }

        .more-info {
            margin: 0 0 0 24px;

            @include mq($from: 1350px) {
                margin: 0 85px 85px 85px;
            }
        }
    }

    &-thank-you {
        background: var(--white);
        border-radius: $border-radius;
        text-align: center;
        padding: $padding;

        @include mq($from: 768px) {
            max-width: 841px;
        }

        @include mq($from: 1000px) {
            padding: 73px 78px;
        }

        h1 {
            font-size: rem(64px);
            font-weight: 800;
            line-height: inherit;
            margin-top: 0;
            background: var(--gradient-blue);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    //culture page
    &-blue-outline {
        background: none;
        color: var(--white);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 24px;

        h1 {
            font-size: rem(32px);
            margin: 0 0 40px 0;
            line-height: 1.5;

            @include mq($from: 700px) {
                margin-bottom: 32px;
            }

            @include mq($from: 1440px) {
                line-height: 1;
                font-size: rem(50px);
            }
        }

        p {
            margin: 0;
        }

        &__left:before {
            border-radius: 20px 20px 0 0;
            background: linear-gradient(105deg, #3023AE, #7C48C3);
        }

        &__right:before {
            border-radius: 0 0 20px 20px;
            background: linear-gradient(105deg, #7645C1, #C86DD7);
        }

        @include mq($from: tablet) {
            &__left:before {
                border-radius: 20px 0 0 20px;
            }

            &__right:before {
                border-radius: 0 20px 20px 0;
            }
        }

        @include mq($from: desktop) {
            &__left:before {
                border-radius: 20px 0 0 20px;
            }

            &__right:before {
                border-radius: 0 20px 20px 0;
            }
        }

        &__left, &__right {
            position: relative;
            max-width: 325px;
            background-color: var(--black);

            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                padding: 2px;
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
            }

            @include mq($from: 1024px) {
                max-width: 400px;
            }

            @include mq($from: 1440px) {
                max-width: 650px;
            }
        }

        &__left {
            position: relative;
            padding: 40px 24px 80px 24px;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
            margin-bottom: 2px;

            .btn-vacancies {
                position: absolute;
                bottom: -34px;
                left: 58px;
                z-index: 1;

                @include mq($from: 1024px) {
                    left: 145px
                }

                @include mq($from: 1440px) {
                    left: 387px;
                }
            }

            @include mq($from: 700px) {
                border-top-right-radius: 0;
                border-bottom-left-radius: 24px;
                margin-bottom: 0;
                margin-right: 2px;

            }
            @include mq($from: 1440px) {
                padding: 55px 60px 52px;

            }
        }

        &__right {
            padding: 80px 24px 103px 24px;
            border-bottom-left-radius: 24px;
            border-bottom-right-radius: 24px;
            position: relative;

            .btn-contact {
                position: absolute;
                bottom: -34px;
                left: 58px;
                z-index: 1;

                a {
                    text-align: center;
                    padding: 12px 0;
                    width: 209px !important;
                }

                @include mq($from: 1024px) {
                    left: 145px
                }
                @include mq($from: 1440px) {
                    left: 387px;
                }

            }

            @include mq($from: 700px) {
                border-top-right-radius: 24px;
                border-bottom-left-radius: 0;
                padding: 40px 24px 103px 24px;

            }
            @include mq($from: 1440px) {
                padding: 55px 60px 52px;
            }
        }

        .link__arrow {
            display: flex;
            flex-direction: row;
            margin-bottom: 24px;

            svg {
                height: 20px;
                width: 80px;
                margin-left: 20px;
            }
        }
    }
}
