.php-form {
    @extend .form-container;
    padding: 64px 24px;

    @include mq($from: 769) {
        padding: 80px 64px;
    }

    .success {
        display: block;
        color: green;
        font-size: 16px;
        margin-top: 3rem;
    }

    .validation-error {
        font-weight: normal;
        color: red;
    }

    .optional {
        color: #9fa1a3;
        font-size: 14px;
    }

    label {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-weight: 600;
        line-height: 2.22;
        font-size: 18px;

        input {
            margin-top: 8px;
        }
    }

    input {
        display: block;
        width: 100%;
        height: 54px;
        padding: 0px 14px;
        border: solid 1px #d8d8d8;
        border-radius: 8px;
        font-family: var(--font-Montserrat);
        font-size: 18px;

        &.has-error {
            border-color: red;
        }

        &::placeholder {
            color: #9fa1a3;
        }
    }

    &__form-row {
        width: 100%;


        @include mq($from: 769) {
            display: flex;
            max-width: 680px;
        }

        &--flex-end {
            align-items: flex-end;
            justify-content: space-between;
        }

        &:not(first-of-type) {
            margin-top: 48px;
        }

        > label {
            &:not(:nth-child(1)) {
                margin-top: 48px;
                @include mq($from: 769) {
                    margin-top: unset;
                    margin-left: 40px;
                }
            }
        }
    }

    .btn {
        border-radius: $border-radius;
        text-transform: none;
        padding: 0 42px;
        margin-top: 16px;
        width: 100%;

        @include mq($from: tablet) {
            margin-top: unset;
            width: auto;
        }
    }

    &__file-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 230px;

        &__label {
            align-self: flex-start;
            font-weight: 600;
            line-height: 2.22;
            font-size: 18px;
        }

        &__image {
            height: 90px;
            width: 90px;
            border-radius: 8px;
            border: 1px solid #d8d8d8;
            margin-top: 10px;

            &__delete {
                display: none;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: black;
                position: absolute;
                top: -12px;
                right: -12px;
            }

            &.has-image {
                background-image: url("/images/icons/document.png");
                background-size: contain;
                position: relative;

                .php-form__file-upload__image__delete {
                    top: -12px;
                    right: -12px;
                    border-radius: 50%;
                    font-weight: 600;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;
                    background: black;
                    position: absolute;
                }
            }
        }

        &__button {
            border-radius: $border-radius-btn;
            border: 2px solid var(--purple);
            margin-left: 0;
            margin-top: 16px;
            padding: 4px 42px;
            text-align: center;
            font-weight: bold;
            font-family: var(--font-Montserrat);
            -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

            &:hover {
                box-shadow: 0 2px 16px 0 var(--purple);
                cursor: pointer;
            }
        }

        &__input {
            display: none !important;
        }
    }
}
