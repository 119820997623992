* {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

html, body {
    height: 100%;
    margin: 0;
    font-family: var(--font-Montserrat);
    scroll-behavior: smooth;
    font-size: $font-size-mobile;

    @include mq($from: 768px) {
        font-size: $font-size;
    }
}

body {
    background: #F9F9F9;
    overflow-x: hidden;
    overflow-y: auto;

    &.no-scroll {
        overflow: hidden;
    }
}

.data-container {
    //max-width: 100%;
    //position: relative;
    //overflow: hidden;
    //width: 100%;
}

#svg-sprite-container {
    display: none;
}

:focus {
    outline: none;
    border: none;
}

img {
    width: 100%;
    vertical-align: center;
}

.cl {
    clear: both;
}

.acceleration {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

#mouseicon {
    --border-width: 20px;
    --size: min(calc(100vw - var(--border-width) * 2), 500px);
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: var(--border-width) solid rgba(52, 195, 235, 1);
    z-index: 0;
    filter: blur(70px);
    opacity: .9;
    pointer-events: none;
    transform: translateZ(0) translateX(-50%) translateY(-50%);
    transition-duration: 200ms;
    transition-timing-function: ease-out;
    animation-duration: 7s;
    animation-name: iconanim;
    animation-iteration-count: infinite;
}

/* container styles */
.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #000;
    z-index: 2;
    padding-bottom: 278px;

    &.flex {
        height: auto;

        &.subheader {
            padding-top: 100px;
        }
    }

    &.fix {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        background: transparent;
    }

    &.pattern-dark {
        background: url("/images/patterns/pattern-dark-header@3x.png") repeat;
        background-size: 65%;
    }

    &.pattern-light {
        background: url("/images/patterns/pattern-light@3x.png") no-repeat #F9F9F9 right 40px;
        background-size: 60%;
    }

    &.pattern:before {
        position: absolute;
        display: block;
        content: " ";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .2;
        background: #16171A url("/images/patterns/pattern-set@2x.png") repeat;
        background-size: 210px;
    }

    &.white {
        background-color: #FFF;
    }

    &.blueish {
        background: #DEE1E9;
    }

    &.gradient {
        background: rgb(247, 252, 255) linear-gradient(0deg, rgba(247, 252, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    }

    &.darkgrey {
        background-color: #16171A;
    }

    &.black {
        background-color: #000;
    }

    &.top {
        align-items: flex-start;
    }

    &.overlay {
        z-index: 2;
    }

    &.trans {
        background: none;
    }

    &.full {
        height: 100vh;
    }

    &.thumbnails {
        display: block;
        height: 420px;
        overflow: hidden;
        background: url("/images/hero.png") no-repeat center center;
        background-size: cover;

        @include mq($until: $mobile-view-width) {
            display: none;
        }
    }
}

.mobile-collage {
    @include mq($from: $mobile-view-width) {
        display: none;
    }
}

.video-container {
    position: relative;
    text-align: center;
    max-width: 1100px;
    margin: 0 auto;
    border-radius: $border-radius;
    overflow: hidden;


    .video-poster {
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center center;
        background-size: cover;
        opacity: 1.0;
        z-index: 10;
        height: 100%;
        width: 100%;
        transition: all 0.3s ease-in;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        .video-play {
            width: 70px;
            height: 70px;
            cursor: pointer;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #CF10D9;
            -webkit-box-shadow: 8px 16px 16px 0 rgba(0, 0, 0, 0.5);
            box-shadow: 8px 16px 32px 0 rgba(0, 0, 0, 0.25);
            transition: all 0.3s ease;

            &:hover {
                transform: scale(1.2);
            }

            &__triangle {
                border-top: 16px solid transparent;
                border-bottom: 16px solid transparent;
                border-left: 24px solid white;
                margin-left: 10px;
            }
        }
    }

    &::before {
        display: block;
        content: "";
        padding-top: 56.25%;
    }

    .vimeo-player {
        border-radius: $border-radius;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.wrapper {
    min-height: 100px;
    box-sizing: border-box;
    margin: 0 auto;
    flex: 1;
    @include mq($from: 970px) {
        max-width: $max-width;
    }
}

.row {
    display: flex;
    flex-direction: column;

    @include mq($from: 970px) {
        flex-direction: row;
    }

    &.full {
        width: 100%;
    }

    &.color {
        min-height: 100px;
        background: #E8ECF2;
    }

    &.mason {
        flex-wrap: wrap;
    }

    .column {
        flex: 1;
        position: relative;
        min-height: 100px;
        padding: 30px;
        box-sizing: border-box;

        &.squeeze {
            padding: 10px;
        }

        &:nth-child(2) {
            margin-top: -60px;

            @include mq($from: 970px) {
                padding-top: 100px;
                margin-top: unset;
            }
        }
    }
}

/** cards **/

.cardlist {
    padding-top: 0;
    transform: rotate(0deg) translateY(0px);
}

.card-holder {
}

.box-shadow {
    box-shadow: 0 40px 54px 0 rgba(0, 0, 0, 0.07), 0 1px 6px 0 rgba(0, 0, 0, 0.04);

    &-small {
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.2);
    }
}

.background-light-gray {
    background: #F9F9F9;
}

/** gallery **/

.gallery {
    img {
        width: 65%;
        border-radius: 10px;

        &.float {
            position: absolute;
            top: 50%;
            left: 30%;
        }
    }
}

.thumb-column {
    float: left;
    width: 25%;
    position: relative;

    .thumb {
        position: relative;
        width: 100%;
        height: 200px;
        overflow: hidden;

        .info {
            position: absolute;
            bottom: 20px;
            left: 20px;

            h4, p {
                color: #FFF;
                padding: 0;
            }

            p {
                font-size: 10px;
            }
        }
    }
}

.scroll-more {
    position: absolute;
    bottom: 7vh;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: contain;
    background-image: url("/images/svg/down-arrow.svg#img");
    opacity: 0;
    filter: brightness(0) invert(1);
    animation-name: scrollnow;
    animation-duration: 500ms;
    animation-delay: 500ms;
    animation-fill-mode: forwards;
    transition-timing-function: ease-out;
}

.cookie-btn {
    position: absolute;
    bottom: 30px;
    right: 30px;
    padding: 6px 18px;
    background-color: #FFF;
    border-radius: 20px;
    z-index: 100;

    a {
        text-decoration: none;
    }
}

.grid-max-width {
    background: $gradient-grey;
    display: grid;
    grid-template-columns: 1fr clamp(min(100%, 25rem), 90%, #{$max-width}) 1fr;
    padding-bottom: min(7.5%, 5rem);

    > * {
        grid-column: 2 / 3;
    }
}

.svg-defs {
    display: none;
}

.text-large-image {
    display: grid;
    grid-template-areas: "text"
                         "img";
    grid-auto-rows: auto;
    grid-auto-columns: 1fr;
    background-color: #F9F9F9;

    @include mq($from: 1100px) {
        grid-template-areas: "text img";
    }

    &__text {
        display: grid;
        background-color: #F9F9F9;
        grid-area: text;
        margin: 64px 48px 48px 48px;

        @include mq($from: 1100px) {
            justify-content: flex-end;
            align-items: center;
        }
    }

    &__img {
        grid-area: img;
        margin-top: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 600px;

        @include mq($from: 768px) {
            display: grid;
            margin-top: 0;

        }
    }

    &__additional-text {
        margin: 24px 48px 48px 26px;

        @include mq($from: 1100px) {
            justify-content: flex-end;
            align-items: center;
        }

        li {
            line-height: 2;
        }
    }
}
