.page-header {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 15rem;
    padding: 2rem;
    text-align: center;

    &--title {
        font-size: clamp(2.5rem, 8vw, 3.75rem);
        line-height: 1.25;
    }
}
