@keyframes arrow-animation {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(5px);
    }
}

@keyframes scrollnow {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes show {
    0% {
        transform: skewY(14deg) translateY(90%) translateZ(0);
    }
    70%  {
        transform: skewY(-4deg) translateY(40%) translateZ(0);
    }
    100% {
        transform: skewY(0) translateY(0%) translateZ(0);
    }
}

@keyframes hide {
    0% {
        transform: skewY(0) translateY(0%) translateZ(0);
    }
    70%  {
        transform: skewY(8deg) translateY(60%) translateZ(0);
    }
    100% {
        transform: skewY(14deg) translateY(90%) translateZ(0);
    }
}

@keyframes iconanim {
    0% {
        border-radius: 50%;
        border: 20px solid #0DB8E6;
    }
    40% {
        border-radius: 50%;
        border: 20px solid #0DB8E6;
    }
    50% {
        border-radius: 50%;
        border: 20px solid #D511DF;
    }
    90% {
        border-radius: 50%;
        border: 20px solid #D511DF;
    }
    100% {
        border-radius: 50%;
        border: 20px solid #0DB8E6;
    }
}

@keyframes gradient {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 0;
    }
}
