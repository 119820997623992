.btn {
    display: inline-block;
    background-image: linear-gradient(to right, var(--blue), var(--purple));
    padding: $button-padding;
    border-radius: $border-radius-btn;
    border: none;
    cursor: pointer;
    transition: box-shadow .3s;
    text-decoration: none;
    text-align: center;

    &--lowercase {
        text-transform: none;
    }
    font-size: 12px;
    font-weight: bold;
    color: var(--white);

    &:hover {
        box-shadow: 0 0 20px var(--purple);
    }

    &--transparent, &--black {
        &:hover {
            box-shadow: unset;
        }
    }

    &--transparent {
        background: transparent;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: unset;
        color: var(--black);
        padding: 0;
    }

    &--black {
        background-image: unset;
        background-color: var(--black);
        text-transform: unset;
        font-weight: 600;
        letter-spacing: 1px;
    }

    &.disabled {
        opacity: 0.32;
        pointer-events: none;
    }
}

// Ask what this does
.hs-button {
    margin: 1rem 0;
    position: relative;
    display: inline-block;
    padding: 12px 45px;
    background: var(--gradient-blue);
    border: none;
    border-radius: $border-radius;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    font-size: 12px;
    font-weight: bold;
    color: var(--white);
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0);

    &:hover {
        box-shadow: 0 2px 16px 0 var(--purple);
        cursor: pointer;
    }

    ::first-letter {
        text-transform: capitalize;
    }
}

.link {
    &--default {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        text-decoration: none;
        font-weight: 600;
        transition: 200ms ease-in-out;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
        text-align: right;

        svg, i {
            width: 11px;
            height: 21px;
            margin-left: 20px;
            margin-top: 5px;
            animation-duration: 0.5s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease-in-out;
        }

        &:hover {
            color: var(--blue);

            svg, i {
                animation-name: arrow-animation;
            }
        }
    }

    &--underline:hover {
        text-decoration: underline;
        padding-bottom: 3px;
    }

    &--arrow {
        display: flex;
        align-content: flex-start;
        align-items: center;

        span {
            font-weight: 600;
        }

        &--icon {
            display: inline-flex;
            margin-left: 15px;

            svg {
                width: 79px;
                height: 16px;
            }
        }

        &:hover {
            .link--arrow--icon {
                animation-duration: 0.5s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: ease-in-out;
                animation-name: arrow-animation;
            }
        }
    }
}
