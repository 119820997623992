.more-info {
    display: flex;
    align-content: flex-start;
    align-items: center;

    &__whole-item-clickable {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__link {
        display: flex;
        align-content: flex-start;
        align-items: center;
        justify-content: space-around;
        gap: 10px;

        svg {
            width: 79px;
            height: 16px;
        }

        &__arrow {
            margin-bottom: -3px;
        }
    }

    &--title {
        color: $gray;
        margin-top: 12px;
    }

    .menu-desktop__container__submenu__moreinfo & {
        color: var(--white);

        a {
            color: var(--white);
        }

        svg path {
            fill: var(--white);
        }
    }

    &:hover {
        .more-info__link__arrow {
            animation-duration: 0.5s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease-in-out;
            animation-name: arrow-animation;
        }
    }

    &__link {
        font-weight: 600;
        text-decoration: none;

        @include mq($from: tablet) {
            padding-right: 24px;
        }
    }

    &__arrow {
        padding-top: 4px;
    }
}
