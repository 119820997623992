header, nav {
    position: relative;
}

.hamb {
    position: fixed;
    top: 30px;
    right: 20px;
    width: 20px;
    height: 14px;
    z-index: 100000;
    display: block;

    @include mq($from: 1100px) {
        display: none;
    }

    div i {
        position: absolute;
        width: 20px;
        height: 2px;
        border-radius: 1px;
        background: #FFF;
        transition: transform 300ms ease-out;
    }

    div i:nth-child(2) {
        width: 15px;
        right: 0;
        top: 6px;

        :hover div i:nth-child(2) {
            transform: translateX(-5px);

        }
    }

    div i:nth-child(3) {
        bottom: 0;

    }

    &.active {

        div i:nth-child(1) {
            transform: translateY(6px) rotate(45deg);
        }

        div i:nth-child(2) {
            opacity: 0;
        }

        div i:nth-child(3) {
            transform: translateY(-6px) rotate(-45deg);
        }
    }
}

.navigation-animation-filler {
    height: 80px;
}

#services-toggle-link {
    .open {
        transform: rotate(180deg);
    }
}

.navigation-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5000;
    transition: 300ms ease-out;
}

.scrolled-navigation {
    background-color: var(--gray-4);
    transition: 300ms ease-out;
    z-index: 50000;
}

.navigation-top-bar {
    background: #0E141A;
    display: flex;
    align-items: center;
    z-index: 5000;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    transition: top 0.6s ease-in-out;
    position: relative;
    justify-content: space-between;

    &__right {
        li {
            margin-right: 40px;
        }
    }

    &__links {
        margin-right: 56px;
        display: flex;
        justify-content: space-between;

        @include mq($until: 1100px) {
            display: none;
        }

        .mainlink {
            color: var(--white);
            list-style: none;
            font-family: var(--font-Montserrat);
            font-size: 14px;
            font-weight: 700;
            text-decoration: none;
            text-transform: capitalize;

            &:hover {
                border-bottom: 1px solid white;
                cursor: pointer;
            }

            &.active {
                border-bottom: 1px solid white;
            }
        }

        .contact-button {
            background-image: linear-gradient(to right, #00B4E5, #CF10D9);
            padding: $button-padding;
            border-radius: $border-radius-btn;
            border: none;
            cursor: pointer;
            transition: box-shadow .3s;
            text-transform: capitalize;


            &:hover {
                box-shadow: 0 0 20px #CF10D9;
            }
        }

        .languagelink {
            color: #00B4E5 !important;
            border-bottom: none !important;
            font-weight: 700;
        }


        ul {
            justify-self: flex-start;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            list-style: none;

            li {
                list-style: none;

                a {
                    color: var(--white);
                    font-family: var(--font-Montserrat);
                    font-size: $font-size-mobile;
                    font-weight: 800;
                    text-decoration: none;
                    @include mq($from: 768px) {
                        font-size: $font-size;
                    }
                }
            }
        }
    }

    .logo {
        display: flex;
        align-items: center;
        width: 195px;
        min-width: 195px;
        height: 122px;
        margin-left: 80px;
        opacity: 1;
        transform: translateY(0);
        transition: all 0.6s ease-in-out;

        @include mq($until: 1100px) {
            margin-left: 24px;
        }

        img {
            width: 100%;
        }

        svg {
            width: 100%;

            path {
                fill: white;
            }
        }

        path {
            fill: white;
        }

        .sticky & {
            opacity: 0;
            transform: translateY(30px);
        }
    }
}

.navigation-sub-bar {
    box-shadow: 0 17px 35px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    height: 0;
    position: relative;
    z-index: 50000;
    transition: height 0.3s;

    &.open {
        height: 57px;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 57px;
        background: $edv-gray;

        &__links {
            display: none;
            grid-area: links;

            @include mq($from: $mobile-view-width) {
                display: flex;
                justify-content: center;
                list-style-type: none;
                position: relative;
                width: 100%;

                .link {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }

            .link {
                font-weight: bold;
                color: white;
                opacity: 0.5;
                font-size: 12px;
                cursor: pointer;
                transition: 200ms ease-in-out;
                text-align: center;
                @include mq($from: 1046px) {
                    font-size: 14px;
                }

                &:hover {
                    opacity: 1;
                }

                > * {
                    pointer-events: none;
                }

                .chevron {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    margin-left: 8px;
                    background-image: url('/images/svg/chevron-down.svg');
                    background-repeat: no-repeat;
                    transition: 200ms ease-in-out;
                }

                &.active {
                    opacity: 1;

                    .chevron {
                        transform: rotate(180deg);
                    }
                }

                :last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.menu-desktop {
    display: none;

    @include mq($from: $mobile-view-width) {
        display: block;
    }

    &__container {
        position: absolute;
        z-index: 1000;
        left: 50%;
        opacity: 0;
        width: 80%;
        min-height: min(530px, 100vh);
        transform: translate(-50%, -640px);
        transition-property: opacity, transform;
        transition-duration: 220ms, 200ms;
        transition-timing-function: cubic-bezier(0.42, 0.0, 0.58, 1.0);
        background: rgb(246, 246, 246) linear-gradient(180deg, var(--white) 0%, #FBFBFB 100%);
        padding: 0;

        .sticky & {
            position: fixed;
            top: 80px;
        }

        @include mq($from: 1200px) {
            max-width: 1400px;
        }

        &.active {
            transform: translate(-50%, 0);
            opacity: 1;
            box-shadow: 0 0.833rem 0.833rem -0.833rem rgb(0 0 0 / 0.2);
        }

        &__submenu {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: none;

            &.active {
                display: grid;
                grid-gap: 0;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1.2fr;
                grid-template-areas: "intro sublinks"
                                     "moreinfo sublinks";

                @include mq($from: 1200px) {
                    grid-template-columns: 1fr 1fr 414px;
                    grid-template-rows: auto;
                    grid-template-areas: "intro sublinks moreinfo";
                    border-right: solid 1px #CF10D9;
                }
            }

            .grid-item {
                margin: 0;
            }

            &__intro {
                grid-area: intro;
                background-color: white;
                padding: 24px 64px;
                min-height: 100%;
                overflow: hidden;
                @include mq($from: 1200px) {
                    padding: 64px;
                }

                h3 {
                    font-size: 20px;
                    margin-bottom: 0;
                }

                .text {
                    font-size: 14px;
                    line-height: 32px;
                    padding-top: 16px;
                }
            }

            &__sublinks {
                grid-area: sublinks;
                background-color: white;
                padding: 86px 80px 30px 0;
                text-align: right;
                min-height: 100%;
                overflow: hidden;

                ul {
                    list-style-type: none;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                }

                li {
                    margin-bottom: 16px;
                    padding-left: 14px;
                    text-align: right;

                    a {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-start;
                        text-decoration: none;
                        font-weight: 600;
                        transition: 200ms ease-in-out;
                        font-size: 14px;
                        line-height: 24px;
                        cursor: pointer;
                        text-align: right;

                        svg, i {
                            width: 11px;
                            height: 21px;
                            margin-left: 20px;
                            margin-top: 5px;
                            animation-duration: 0.5s;
                            animation-iteration-count: infinite;
                            animation-direction: alternate;
                            animation-timing-function: ease-in-out;
                        }

                        &:hover {
                            color: #0DB8E6;

                            svg, i {
                                animation-name: arrow-animation;
                            }
                        }
                    }


                }

                li:last-of-type {
                    margin-bottom: unset;
                }
            }

            &__moreinfo {
                grid-area: moreinfo;
                background: linear-gradient(to right, #00B4E5, #CF10D9);
                padding: 24px 64px;
                align-content: center;
                min-height: 100%;
                overflow: hidden;
                @include mq($from: 1200px) {
                    padding: 64px;
                }

                h3 {
                    color: white;
                    font-size: 20px;
                    margin-bottom: 12px;
                }

                .text {
                    color: white;
                    font-size: 14px;
                    line-height: 32px;
                    padding-bottom: 24px;
                }

                a {
                    position: relative;
                }
            }
        }
    }
}

.menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background: $edv-gray;
    z-index: 7000;
    transition: all 1s cubic-bezier(.11, .99, .66, 1);
    pointer-events: none;
    transform: translate3d(0, 0, 0);
    clip-path: circle(0% at 105vw -5vh);
    overflow: hidden;

    &.active {
        clip-path: circle(100%);
        pointer-events: auto;
    }

    &__main {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;

        &__top {
            padding-bottom: 8px;

            .logo {
                display: flex;
                align-items: center;
                width: 195px;
                height: 80px;
                margin-left: 24px;
                margin-bottom: 62px;
                opacity: 0;
                transition: all 0.6s ease-in-out;

                .active & {
                    opacity: 1;
                }
            }

            img {
                width: 100%;
            }

            svg {
                width: 100%;

                path {
                    fill: var(--white);

                    @include mq($from: $mobile-view-width) {
                        fill: #0E141A;
                    }
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin-left: 24px;
                margin-right: 24px;

                li a {
                    display: block;
                    color: var(--white);
                    font: 800 12px/18px var(--font-Montserrat);
                    letter-spacing: 1px;
                    border-bottom: 1px solid #9FA1A334;
                    text-decoration: none;
                    padding-bottom: 16px;
                    margin-bottom: 32px;

                    &:hover {
                        border-bottom: 1px solid white;
                    }

                    &.active {
                        border-bottom: 1px solid white;
                    }
                }
            }
        }

        &__bottom {

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            &__list {
                ul {
                    list-style: none;
                    padding: $padding;

                    li {
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        font: 600 14px/18px var(--font-Montserrat);
                        padding: 10px 16px;
                        align-content: center;
                        cursor: pointer;
                        letter-spacing: $letter-spacing;
                        color: white;
                        border-bottom: 1px solid #9FA1A334;

                        i {
                            margin-left: 10px;
                        }

                        > * {
                            pointer-events: none;
                        }

                        .mobile-menu-arrow {
                            width: 24px;
                            height: 24px;
                            animation-duration: 0.5s;
                            animation-iteration-count: infinite;
                            animation-direction: alternate;
                            animation-timing-function: ease-in-out;
                        }

                        &:hover {
                            color: $blue;

                            .mobile-menu-arrow {
                                animation-name: arrow-animation;
                            }
                        }
                    }
                }
            }

            &__links {
                text-align: center;

                ul {
                    padding: 0 $padding;
                }

                a {
                    display: block;
                    padding: 10px 0;
                    color: $blue;
                    margin-top: 17px;
                    text-align: center;
                    text-decoration: none;
                    font: 800 1rem/1.5rem var(--font-Montserrat);
                    letter-spacing: $letter-spacing;

                    &.btn {
                        display: block;
                        background-image: $gradient-blue;
                        padding: 13px 40px;
                        border-radius: $border-radius-btn;
                        border: none;
                        cursor: pointer;
                        transition: box-shadow .3s;
                        color: #fff;

                        &:hover {
                            color: #fff;
                            box-shadow: 0 0 20px $purple;
                        }
                    }
                }
            }
        }
    }

    &__sub {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 100vh;
        transform: translateX(100vw);
        transition: 300ms ease-out;
        opacity: 0;
        overflow: hidden;

        &.active {
            transform: translateX(0);
            opacity: 1;
        }

        &__header {
            height: 80px;
            background: $edv-gray;

            .arrow-left {
                position: absolute;
                top: 28px;
                left: $padding;

                img {
                    width: 20px;
                    height: 23px;
                }
            }
        }

        &__content {
            position: absolute;
            top: 80px;
            right: 0;
            bottom: 0;
            left: 0;
            background: $edv-gray;
            overflow: hidden;

            &.white {
                background: white;
            }

            &__submenu {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                height: 100%;
                display: none;
                color: black;

                &.active {
                    display: grid;
                    grid-template-rows: 230px auto;
                    grid-template-areas: "intro"
                                         "sublinks";
                }

                &__intro {
                    grid-area: intro;
                    padding: 24px;
                }

                &__sublinks {
                    grid-area: sublinks;

                    ul {
                        list-style-type: none;
                        padding: 0 $padding;
                        margin: 0;
                    }

                    li {
                        margin-bottom: 16px;

                        a {
                            display: flex;
                            align-content: center;
                            text-decoration: none;
                            transition: 200ms ease-in-out;
                            text-align: right;
                            font: 600 1.2rem/1.5rem var(--font-Montserrat);
                            margin-bottom: 24px;
                            cursor: pointer;
                            letter-spacing: $letter-spacing;
                            border-bottom: 1px solid $gray;
                            padding-bottom: 16px;
                        }

                        svg, i {
                            margin-left: 7.5px;
                            width: 11px;
                            height: 21px;
                            display: flex;
                            align-items: center;
                        }
                    }

                    li:last-of-type {
                        margin-bottom: unset;
                    }
                }
            }
        }
    }
}

.subservice-moreinfo {
    display: none;

    &.active {
        display: block;
    }
}
