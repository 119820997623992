.contact {

    &--container {
        //background: linear-gradient(180deg, #fff, #f9f9f9);
        display: grid;
        gap: 3.5rem 0;
        grid-template-columns: 1fr clamp(min(100%, 25rem), 90%, 1400px) 1fr;
        padding-bottom: min(7.5%, 5rem);

        > * {
            grid-column: 2 / 3;
        }
    }
    h2{
        margin-bottom: 24px;
    }

    &--header {
        align-items: center;
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 10rem;
        padding: 2rem;
        text-align: center;

        &__title {
            font-size: clamp(2.5rem, 8vw, 3.75rem);
            line-height: 1.25;
        }
    }

    &--info {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem 5vw;
        @media (min-width: 850px) {
            justify-content: center;
        }

        &__sections {

            .phoenix-contact-section {
                h4 {
                    margin-bottom: 8px;
                }
            }

            section {
                margin-bottom: 1em;
                padding: 0.75em 1.5em;

                h2 {
                    font-size: 2rem;
                    margin-bottom: 0.5em;
                }

                address {
                    display: flex;
                    flex-direction: column;
                    font-style: normal;
                    line-height: 2;

                    a {
                        text-decoration: none;
                        width: max-content;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .contact--card {
        background: #fff;
        border-radius: $border-radius;
        cursor: default;
        margin: 0;
        padding: 7.5%;
        position: relative;
        width: 100%;
        min-height: 1040px;
        z-index: 1;
        @media (min-width: 850px) {
            box-shadow: 0 -18px 70px #00000010;
            width: clamp(min(100%, 15rem), 50%, 60rem);
        }

        &__intro {
            margin: 1.5rem 0;
        }
    }

    &--vacancies {
        padding: 0 5vw;

        &__list {
            display: grid;
            gap: 0 5%;
            grid-template-columns: repeat(
                    auto-fit,
                    minmax(min(100%, 20rem), 1fr)
            );
            list-style: none;
            padding: 0;

            &-item {
                margin-bottom: 2.5rem;
                width: 100%;
            }
        }
    }
}
