.knowledge {

    .knowledge-title {
        margin-top: 166px;
        margin-bottom: 54px;

        h1 {
            text-align: center;
        }
    }

    &-cards-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;

        @include mq($from: 1010px) {
            flex-direction: row;
            justify-content: space-around;

            .block-image-info {
                margin: 0 24px;
            }
        }
    }
}

