@use "sass:meta";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$font-Montserrat:           'Montserrat', sans-serif;
$font-MontserratOutlined:   'MontserratOutlined', sans-serif;

$font-size:         rem(16px);
$font-size-mobile:  rem(14px);
$letter-spacing:    rem(1px);

/** breakpoints & widths **/
$mobile-view-width: 1100px;
$max-width:         1400px;
$max-width-small:   1160px;
$max-width-tiny:    680px;

/** spacings **/
$padding:           24px;
$border-radius:     24px;
$border-radius-btn: 22px;
$button-padding:    11px 36px;

/** shadows **/
$box-shadow:        rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

/** colors **/
// Old
$black: #000000;
$edv-gray: #0E141A;
$gray-dark: #0E141A;
//$gray: rgba(159, 161, 163, 0.2);
$gray-light: #D8D8D8;
$white: #fff;

// new
$black: #000000;
$gray-4: #0E141A;
$gray-3: #1e1e1e;
$gray-2: #9FA1A3;
$gray-1: #D8D8D8;
// Check this one
$gray: rgba(159, 161, 163, 0.2);
$white: #fff;
$blue: #00b4ff;
$purple: #ff00ff;
$yellow: #ffb400;
$orange: #ff7800;
$gradient-blue: linear-gradient(to right, $blue, $purple);
$gradient-gray: linear-gradient(180deg, $white, $gray-1);

// Old "grey"
$gradient-grey: linear-gradient(180deg, $white, $gray-1);

:root {
    --info-paddings: 30px;
    --card-radius: #{meta.inspect($border-radius)};

    --black: #{$black};
    --gray-4: #{$gray-4};
    --gray-3: #{$gray-3};
    --gray-2: #{$gray-2};
    --gray-1: #{$gray-1};
    --white: #{$white};
    --blue: #{$blue};
    --purple: #{$purple};
    --yellow: #{$yellow};
    --orange: #{$orange};
    --gradient-blue: #{$gradient-blue};
    --gradient-gray: #{$gradient-gray};
    --box-shadow: #{$box-shadow};

    --font-Montserrat: #{meta.inspect($font-Montserrat)};
    --font-MontserratOutlined: #{meta.inspect($font-MontserratOutlined)};
}
