.blog {
    .carlist {
        padding-bottom: 100px;
    }
}

.blog-title-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 24px 24px 24px;

    @include mq($from: desktop) {
        padding: 0 600px 0 0;
    }

    h1 {
        @include mq($until: desktop) {
            font-size: 24px;
            line-height: 32px;
        }
    }
}

.top-section-flex-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: $border-radius;
    background-color: white;
    position: relative;
    top: -20px;
    z-index: 99;
    height: 100%;

    @include mq($from: desktop) {
        padding: 80px;
        flex-direction: row;
        top: -256px;
        align-items: flex-start;
    }

    &__column-left {
        width: 100%;
        line-height: 40px;

        img {
            max-width: 100%;
            object-fit: contain;
        }

        p {
            margin: 0 0 16px 0;
        }

        @include mq($from: desktop) {
            position: sticky;
            top: 0;
            padding-right: 120px;
        }

        span {
            display: flex;
            align-items: center;

            svg {
                background: var(--blue);
                border-radius: 50px;
                padding: 2px;
                margin-right: 8px;
            }
        }
    }

    &__column-right {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-top: 32px;
        margin-top: 32px;

        @include mq($from: desktop) {
            width: 375px;
            padding-top: 0;
            position: sticky;
            bottom: 1rem;
            align-items: center;
            align-self: flex-end;
            margin-top: 0;
        }

        ul {
            list-style: none;

            li {
                margin-bottom: 16px;
            }
        }

        span {
            display: flex;
            align-items: center;

            svg {
                background: var(--blue);
                border-radius: 50px;
                padding: 2px;
                margin-right: 8px;
            }
        }

    }
}

.bottom-section {
    &__title-container {
        max-width: 756px;
        margin: 0 auto;
    }

    &__white-cards-block {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;

        @include mq($from: desktop) {
            flex-direction: row;
        }
    }
}


.blogs {
    .top-section-flex-container {
        display: grid;
        grid-template-columns: 1fr;

        @include mq($from: desktop) {
            grid-template-columns: 2fr 1fr;
        }

        &__column-left {
            @include mq($from: desktop) {
                padding-right: 120px;
            }

            .wysiwyg-container {
                max-width: unset;
            }
        }

        &__column-right {
            position: relative;
            align-items: start;
            flex-wrap: nowrap;
            bottom: unset;
            width: auto;

            .blog-sidebar-card {
                position: relative;
                display: flex;
                align-items: start;
                gap: 20px;
                margin-bottom: 48px;

                img {
                    min-width: 110px;
                    width: 110px;
                    height: 80px;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 12px;
                }
            }
        }
    }
}
