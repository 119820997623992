//@use "~sass-rem" as *;
@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-BlackItalic.woff2') format('woff2'),
    url('/font/Montserrat-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-Black.woff2') format('woff2'),
    url('/font/Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-Bold.woff2') format('woff2'),
    url('/font/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
    url('/font/Montserrat-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-BoldItalic.woff2') format('woff2'),
    url('/font/Montserrat-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-ExtraBold.woff2') format('woff2'),
    url('/font/Montserrat-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-SemiBold.woff2') format('woff2'),
    url('/font/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-SemiBoldItalic.woff2') format('woff2'),
    url('/font/Montserrat-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-Medium.woff2') format('woff2'),
    url('/font/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-MediumItalic.woff2') format('woff2'),
    url('/font/Montserrat-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-Regular.woff2') format('woff2'),
    url('/font/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-LightItalic.woff2') format('woff2'),
    url('/font/Montserrat-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-ExtraLight.woff2') format('woff2'),
    url('/font/Montserrat-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-Light.woff2') format('woff2'),
    url('/font/Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-ExtraLightItalic.woff2') format('woff2'),
    url('/font/Montserrat-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-ThinItalic.woff2') format('woff2'),
    url('/font/Montserrat-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-Thin.woff2') format('woff2'),
    url('/font/Montserrat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/font/Montserrat-Italic.woff2') format('woff2'),
    url('/font/Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

html, body, h1, h2, h3, h4, p {
    //font-family: var(--font-Montserrat);

    // TODO only use the outlined font
    font-family: var(--font-Montserrat);
}

.fastblur {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3),
    0 2px 2px rgba(0, 0, 0, 0.3),
    0 4px 4px rgba(0, 0, 0, 0.3),
    0 8px 8px rgba(0, 0, 0, 0.3),
    0 16px 16px rgba(0, 0, 0, 0.3);
}

span.outlined {
    font-family: var(--font-MontserratOutlined);
    letter-spacing: 1px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
}

h1 {
    cursor: default;

    @include mq($from: 768px) {
        font-size: rem(60px);
        line-height: rem(64px);
    }

    a.chapterlink {
        color: var(--white);
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        transition: color .5s;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3),
        0 2px 2px rgba(0, 0, 0, 0.3),
        0 4px 4px rgba(0, 0, 0, 0.3),
        0 8px 8px rgba(0, 0, 0, 0.3),
        0 16px 16px rgba(0, 0, 0, 0.3);

        &:hover {
            color: var(--yellow);
            -webkit-text-stroke: 1px yellow;
        }
    }
}

.titles {
    padding: 40px 0 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    h4.uppercase {
        padding: 20px 0;
    }
}

.column.corner h3 {
    width: 50%;
    padding-bottom: 30px;
}

h4.uppercase {
    text-transform: uppercase;
    letter-spacing: .7em;
    font-size: rem(8px) !important;
}

.column {
    &.article {
        p {
            max-width: 80%;
        }

        p.intro {
            padding-bottom: 40px;
            font-weight: 800;
        }
    }

    div.intro {
        font-size: rem(14px);
        line-height: 1.7em;
        max-width: 80%;
        padding: 10px 0;
        color: var(--black);

        .darkmode & {
            color: var(--white);
        }
    }
}

a {
    font-weight: 400;
    color: var(--black);
}

a.none {
    text-decoration: none;
}

a.arrow {
    position: relative;
    display: inline-block;
    text-decoration: none;
    padding: 14px 80px 14px 0;
    font-weight: 800;

    &.white {
        color: var(--white);

        &:after {
            background: url('/images/arrow-white@3x.png') no-repeat;
            background-size: contain;
            transition: transform .3s;
        }
    }

    .darkmode & {
        color: var(--white);
    }

    &:after {
        content: " ";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 60px;
        height: 12px;
        background: url('/images/arrow@3x.png') no-repeat;
        background-size: contain;
        transition: transform .3s;

        .darkmode & {
            background: url('/images/arrow-white@3x.png') no-repeat;
            background-size: contain;
            transition: transform .3s;
        }

        &:hover {
            transform: translateY(-50%) translateX(10px);
        }
    }

    &.flip {
        padding: 14px 0 14px 80px;

        &:after {
            right: auto;
            left: 0;
            top: 40%;
            transform: scaleX(-1);
        }
    }
}

.column.corner a.arrow {
    margin-top: 30px;
}

.ml1, .ml2, .ml3 {
    color: var(--white);
    padding: 0 0;
}

.ml1 .letter,
.ml2 .letter,
.ml3 .letter {
    display: inline-block;
    line-height: 1em;
}

.born {
    width: 40%;
}

@media only screen and (max-width: 768px) {
    a {
        -webkit-tap-highlight-color: transparent;
    }

    .born {
        width: 85%
    }
}


// New code over here
h1 {
    line-height: 1.07;
    font-weight: 800;
    font-size: rem(40px);

    &.highlight-title {
        font-weight: 800;
        line-height: 1.1;
        font-size: rem(48px);
    }
}

h2 {
    font-size: rem(32px);
    font-weight: 800;
    line-height: 1.25;
}

h3 {
    font-size: rem(20px);
    font-weight: 800;
    line-height: 1.6;
}

p {
    font-size: rem(16px);
    line-height: 2;

    &.small-p {
        font-size: rem(14px);
    }

    &.large-p {
        font-size: rem(16px);
        line-height: 2.22;

        @include mq($from: desktop) {
            font-size: rem(18px);
        }
    }
}

.bold {
    font-weight: 600;
}

@include mq($from: desktop) {
    h1 {
        font-size: rem(60px);

        &.highlight-title {
            font-size: rem(80px);
        }
    }

    p {
        font-size: rem(16px);

        &.large-p {
            font-size: rem(18px);
        }
    }
}
