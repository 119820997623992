.cases {
    background: url("/images/patterns/pattern-light@3x.png") no-repeat #F9F9F9 right 40px/60%;

    > * {
        position: relative;
    }

    &--section {
        > .card-image-info {
            --max-width: 65rem;
            @media (min-width: 1100px) {
                margin: 40px auto;
            }
        }
    }

    &-image-big {
        max-width: 1100px;
        @include mq($until: 960px) {
            max-width: 90%;
        }
    }
    &-cards-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .card-image-info {
            width: 100%;
        }

        @include mq($from: 1010px) {
            flex-direction: row;
            justify-content: space-around;
        }
    }
    &-outline-text {

    }
}
.three-image-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    img {
        max-width: 560px;
        padding: 20px;
    }

    @include mq($from: 1010px) {
        flex-direction: row;
        flex-wrap: nowrap;
    }
}
