.longRead-title-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 24px 24px 24px;
    @include mq($from: 1100px) {
        padding: 0 600px 0 0;

    }
}

// Moved styling for .top-section-flex-container is in blog.scss

.image {
    border-radius: 24px;
}

.arrow-down {
    svg {
        height: 24px;
        width: 24px;
        background: #00b4ff;
        border-radius: 50px;
    }

}
a {
    text-decoration: none;
}
