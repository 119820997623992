footer {
    min-height: 860px;
    position: relative;
    z-index: 1;
    background-color: var(--white);
}

.footer-container {
    background: $edv-gray;
    color: white;
    padding-bottom: 125px;
    position: relative;
    z-index: 1;

    @include mq($from: 960px) {
        padding-left: 100px;
        padding-right: 100px;
    }

    * {
        color: white !important;
    }

    a {
        text-decoration: none;
    }

    &__rewards {
        text-align: center;
        padding-top: 80px;

        p {
            margin-bottom: 40px;
        }

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 50px;

            img {
                height: 80px;
                width: auto;
                object-fit: contain;
            }
        }

        @include mq($from: 768px) {
            div {
                flex-direction: row;
            }
        }
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
        padding-top: 78px;

        @include mq($from: 768px) {
            justify-content: space-between;
            padding: 78px 0 0 0;
        }

        nav {
            flex: 1;
            padding: 0 10px;
        }

        h4 {
            color: white;
            margin-bottom: 24px;
            font-size: 18px !important;
        }

        ul {
            list-style: none;
            padding: 0;
            flex-grow: 1;
            flex-basis: 0;
            min-width: 250px;

            li {
                margin-bottom: 16px;
                font-size: 16px;

                a {
                    color: white;
                }

                @include mq($from: 768px) {
                    a:hover {
                        border-bottom: 1px solid white;
                    }
                }
            }
        }
        &--origins{
            a {
                text-transform: capitalize;
            }
        }
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        margin-top: 31px;
        font-size: 16px;

        @include mq($from: 768px) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        p {
            font-size: 16px;
            color: white;
            width: 100%;
            text-align: center;
            @include mq($from: 768px) {
                width: fit-content;
            }
        }

        span {
            margin: 16px 0 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            @include mq($from: 768px) {
                width: fit-content;
            }

            a {
                font-size: 16px;
                color: white;
                padding: 0 24px;
                @include mq($from: 768px) {
                    width: fit-content;
                }
            }
        }
    }
}

.footer {
    h4 {
        font-size: rem(14px);
        color: var(--white);
    }

    a {
        text-decoration: none;
        color: var(--white);
    }
}

.sub-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 24px;
    flex-wrap: wrap;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background-color: var(--white);

    @include mq($until: 900px) {
        justify-content: center;
        &__icon {
            margin-top: 24px;
        }
    }

    &__links {
        a {
            margin-right: 24px;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 24px;

        img {
            max-width: 55px;
        }
    }
}

#locomotive {
    .sub-footer {
        position: unset;
        left: unset;
        right: unset;
        bottom: unset;
    }
}

//TODO: on desktop view, left and right side of footer
//has a different color on background. it should match
//TODO: align @2021 Endeavour agency with the privacy and terms
