.section-outer-gradient-shadows {
    position: relative;

    &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 103px;
        z-index: 2;

        @include mq($from: tablet) {
            width: 225px;
        }

        @include mq($from: desktop) {
            width: 526px;
        }
    }

    &:before {
        left: 0;
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 25%, #000 100%);
    }

    &:after {
        right: 0;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 25%, #000 100%);
    }
}
