.block-culture-story {
    display: flex;
    position: relative;
    justify-content: flex-end;
    flex-wrap: wrap;

    &__image {
        width: 100%;
        height: 195px;
        border-radius: 219px;
        transform: rotate(9deg);
        background-size: cover;
        padding: 0;
        transition: 1.4s linear;

        @include mq($from: mobile) {
            height: 195px;
        }

        @include mq($from: desktop) {
            height: 470px;
        }

        @include mq($from: desktop) {
            width: 437px;
            height: 679px;
            margin-right: 120px;
        }
    }

    &__video {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: var(--black);

        iframe {
            position: absolute;
            width: 100%;
            min-height: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 0;
        }
    }

    .scaleToIframeSize {
        border-radius: 0;
        transform: rotate(0deg);
        transition: 1.4s linear; //play around with this
        width: 100%;
        height: 195px;

        @include mq($from: mobile) {
            height: 300px;
        }

        @include mq($from: tablet) {
            height: 470px;
        }

        @include mq($from: desktop) {
            width: 680px;
            height: 380px;
        }

        & * {
            opacity: 1;
            transition: 1.4s linear;
        }
    }


    &__text-container {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        &:before {
            position: absolute;
            content: " ";
            width: 35vw;
            height: 1px;
            background-color: white;
            top: 70%;
            left: -20rem;
        }

        &__heading {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: space-between;
        }

        h1 {
            word-break: break-all;
            margin: 0;

            &.lastname {
                margin-left: 4rem;
            }
        }

        h2 {
            font-size: 18px;
            font-weight: normal;
            margin-left: 3rem;
        }
    }

    &.center {
        justify-content: center;
    }

    &.right {
        flex-direction: row-reverse;
        justify-content: space-around;

        .block-culture-story__image {
            margin-right: 0;
            margin-left: 120px;
        }
    }
}

.section-story {
    margin-bottom: 300px;

    &:first-of-type {
        margin-top: 125px;
    }

    &:last-of-type {
        margin-bottom: 25vh;
    }
}
