body {
    overflow-x: hidden;
}

.smooth-scroll {
    overflow: hidden !important;
}

[data-scroll-in-section] {
    display: inline-flex;
}

section.afterHorizontal {
    margin-top: -100vh;
}

// New styling
.smooth-scroll-container {
    background-color: var(--gray-4);
}

// From here into intro
.culture--intro--animated-title-container {
    background-image: linear-gradient(to right, #0e141a, rgba(14, 20, 26, 0) 35%, rgba(14, 20, 26, 0) 67%, #0e141a);
    padding-top: 100px;

    .culture--intro--animated-title-wrapper {
        top: -80px;
    }

    .block-core-values {
        margin-top: -100px;
    }

    @include mq($from: tablet) {
        margin-top: 112px;
        padding-top: 220px;

        .culture--intro--animated-title-wrapper {
            top: -180px;
        }
    }
}

.culture {
    // First part of the website (intro)
    &--intro {
        &--section {
            background-color: var(--gray-4);
            min-height: 150vh;
        }

        &--animated-title-wrapper {
            position: relative;

            &--inner {
                position: absolute;
                top: -5rem;
            }
        }

        &--cta-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin-top: 65px;
            padding: 0 30px 100px 30px;

            .btn, .more-info {
                width: fit-content;
            }

            @include mq($from: tablet) {
                gap: 30px;
                flex-direction: row;
                padding: 0 0 100px 0;
                margin-top: 100px;
            }

            @include mq($from: desktop) {
                margin-top: 200px;
            }

            a {
                color: $white;
            }
        }

        &--animated-title-container {
            .u-container {
                position: relative;

                .vertical-text-heading {
                    display: none;
                }

                @include mq($from: desktop) {
                    .vertical-text-heading {
                        display: flex;
                        align-items: center;
                        gap: 40px;
                        transform: rotate(270deg);
                        position: absolute;
                        bottom: -280px;
                        left: -140px;

                        h2 {
                            font-family: var(--font-Montserrat);
                            font-size: rem(12px);
                            font-weight: bold;
                            color: var(--gray-2);
                            letter-spacing: 11.25px;
                            text-transform: uppercase;

                            &:last-child {
                                color: var(--white);
                            }
                        }
                    }
                }
            }
        }
    }

    // Horizontal scrolling section
    &--horizontal-scrolling {
        display: none;
        z-index: 100;
        position: relative;
        padding: 125px 30px 0 30px;
        background-color: var(--gray-4);
        // Comment this on to see the grid how this is build
        //background-color: rgba(255, 0, 0, 0.54);

        @include mq($from: desktop) {
            display: block;
            padding: 125px 0 0 0;
        }

        &--animated-title {
            position: relative;
            z-index: 1;

            span:before {
                content: '';
                background-image: linear-gradient(to right, #0e141a, rgba(14, 20, 26, 0) 15%, rgba(14, 20, 26, 0) 67%, #0e141a);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }

            span:first-of-type {
                position: absolute;
                top: 50%;
            }
        }

        .section-inner {
            min-height: max-content;
            display: grid;
            grid-template-columns: 1fr 1fr;

            .item + .item {
                margin-left: 100px;
            }

            .item {
                min-width: 340px;
                max-height: 85vh !important;
                // Comment this on to see the grid how this is build
                //background-color: rgba(255, 0, 0, 0.54);
                color: var(--white);

                .item-inner {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    max-width: 255px;
                    gap: 10px;
                }

                img {
                    object-fit: cover;
                    object-position: center center;
                    max-width: 100%;
                    width: auto;
                    border-radius: 24px;
                }

                &:first-of-type {
                    img {
                        transform: rotate(-13deg);
                        height: 216px;

                        @include mq($from: 1450px) {
                            height: 316px;
                        }
                    }

                    h2 {
                        position: absolute;
                        bottom: 0;
                        left: 100px;
                    }
                }

                &:nth-of-type(2) {
                    &:before {
                        content: '';
                        background-image: url("/images/culture/haarlem-cut.png");
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        position: absolute;
                        width: 1137.5px;
                        height: 1083px;
                        top: -100px;
                    }

                    & > div {
                        position: relative;
                        z-index: 14;
                    }

                    img {
                        margin-top: 100px;
                        width: 158px;
                        height: 239px;
                        transform: rotate(13deg);

                        @include mq($from: 1450px) {
                            width: 258px;
                            height: 339px;
                        }
                    }
                }

                &:nth-of-type(3) {
                    z-index: 12;

                    h1 {
                        position: absolute;
                        top: -85px;
                        margin-left: -165px;
                    }

                    div {
                        position: absolute;
                        bottom: -30px;

                        img {
                            height: 273px;
                            width: 216px;
                            transform: rotate(-12deg);

                            @include mq($from: 1450px) {
                                height: 373px;
                                width: 316px;
                            }
                        }
                    }
                }

                &:nth-of-type(4) {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .item-inner {
                        max-width: 215px;
                        margin-bottom: -100px;
                    }
                }

                &:nth-of-type(5) {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    z-index: 12;

                    img {
                        width: 228px;
                        height: 199px;
                        transform: rotate(-3deg);
                    }

                    div:last-of-type {
                        img {
                            width: 216px;
                            height: 304px;
                            transform: rotate(7deg);

                            @include mq($from: 1450px) {
                                width: 316px;
                                height: 404px;
                            }
                        }
                    }
                }

                &:nth-of-type(6) {
                    margin-left: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;

                    .item-inner {
                        margin-left: -50px;
                    }

                    .item-inner:nth-of-type(2) {
                        margin-left: auto;
                    }
                }

                &:nth-of-type(7) {
                    z-index: 12;

                    .item-inner {
                        margin-left: auto;
                    }

                    div:first-of-type img {
                        width: 162px;
                        height: 222px;
                        transform: rotate(13deg);

                        @include mq($from: 1450px) {
                            width: 262px;
                            height: 322px;
                        }
                    }

                    img {
                        width: 228px;
                        height: 291px;
                        transform: rotate(15deg);
                    }
                }

                &:nth-of-type(8) {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    gap: 50px;
                    padding-left: 70px;

                    h2 {
                        margin-right: -75px;
                        white-space: nowrap;
                        z-index: 15;
                    }

                    img {
                        width: 177px;
                        height: 230px;
                        transform: rotate(-16deg);
                    }

                    .item-inner {
                        margin-left: auto;
                    }
                }

                &:nth-of-type(9) {
                    position: relative;

                    .item-inner {
                        position: absolute;
                        bottom: -50px;
                        left: -50px;
                    }
                }

                &:nth-of-type(10) {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin-left: 0;
                    margin-right: 125px;
                    z-index: 12;

                    .luminous-circle {
                        width: 1030px;
                        height: 1080px;
                        object-fit: cover;
                        position: absolute;
                        right: 400px;
                        top: 35%;
                        transform: translateY(-50%);
                        z-index: -1;
                    }

                    .item-wrapper {
                        img {
                            width: 766.4px;
                            height: 482px;
                            transform: rotate(8deg);
                            z-index: 12;
                            position: relative;

                            @include mq($from: 1450px) {
                                width: 866.4px;
                                height: 582px;
                            }
                        }
                    }

                    .item-inner {
                        margin: 0 auto;
                        max-width: 350px;
                    }
                }
            }
        }
    }

    // Instead of the horizontal scrolling this is for mobile vertical scrolling
    &--proud-section {
        background-image: url("/images/culture/haarlem-cut.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: var(--gray-4);

        @include mq($from: 768px) {
            display: none;
        }

        .u-container {
            position: relative;

            img {
                width: 117px;
                height: 117px;
                transform: rotate(-5deg);
                border-radius: 8px;

                &:last-of-type {
                    width: 122px;
                    height: 144px;
                    transform: rotate(12deg);
                    position: absolute;
                    right: 60px;
                    margin-top: 40px;
                }
            }
        }

        &--animated-title {
            position: relative;

            div:first-of-type {
                position: absolute;
                top: -5rem;
            }
        }
    }

    // Connect / avatar swiper section
    &--connect-us {
        background-color: var(--gray-4);

        & > div:first-of-type {
            overflow: hidden;
            padding-top: 20px;
        }

        @include mq($from: desktop) {
            margin-top: 150px;
        }

        &--top {
            position: relative;
            padding-top: 100px;

            img {
                width: 100%;
                height: 255px;
                position: absolute;
                object-fit: contain;
                left: 50%;
                transform: translateX(-50%);
                top: -70px;
            }

            .infinite-container{
                position: relative;

                &:before {
                    content: '';
                    background-image: linear-gradient(to right, #0e141a, rgba(14, 20, 26, 0) 35%, rgba(14, 20, 26, 0) 67%, #0e141a);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
            }
        }

        .card-blue-outline {
            .link__arrow svg {
                width: 110px;
            }

            &__left, &__right {
                background-color: transparent;
            }
        }

        .u-container {
            position: relative;

            .culture-people-swiper {
                width: 220px;
                height: 280px;
                overflow: visible;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -140px;

                .swiper-slide {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $white;

                    &-clickable-link {
                        position: absolute;
                        z-index: 10;
                        bottom: 0;
                        left: -60px;

                        @include mq($from: desktop) {
                            left: -165px;
                        }

                        .more-info__link {
                            color: $white;

                            img {
                                width: 30px;
                                height: 30px;
                                object-fit: contain;
                            }
                        }
                    }

                    img {
                        filter: brightness(50%);

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .swiper-slide-clickable-link {
                        opacity: 0;
                    }

                    &.swiper-slide-active {
                        .swiper-slide-clickable-link {
                            opacity: 1;
                        }

                        img {
                            filter: brightness(100%);
                        }
                    }
                }
            }
        }
    }

    &--stories {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 300px;
            bottom: 200px;
            height: 100%;
            width: 315px;
            transform: rotate(180deg);
            background-image: url("/images/patterns/texture-height-full.png");
            background-size: contain;
            background-repeat: no-repeat;
        }

        &--heading {
            padding-top: 100px;

            img {
                width: 100%;
                height: 255px;
                position: absolute;
                object-fit: contain;
                left: 50%;
                transform: translateX(-50%);
                top: -70px;
            }
        }
    }
}


.culture .form-contact {
    background-image: url("/images/culture/hero@2x.png");
    background-size: contain;
    background-position: right bottom;;
    background-repeat: no-repeat;
    background-color: var(--gray-4);
}
