/* hubspot forms */
.form-contact {
    font-family: var(--font-Montserrat);
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 2;
    min-height: 900px;

    @include mq($until: 485px) {
        display: block;
    }
}

.form-container {
    flex-grow: 1;
    position: relative;
    z-index: 2;
    margin: 120px 24px 0 24px;
    bottom: -60px;
    background: var(--white);
    color: var(--black);
    border-radius: $border-radius;
    box-shadow: 0 -18px 50px 0 rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    padding: 20px 40px;

    @include mq($until: 485px) {
        margin: 120px 0 0 0;
    }

    @include mq($from: desktop) {
        flex-direction: row;
        padding: 64px 120px;
    }

    @include mq($from: 1448px) {
        margin: 120px auto 0 auto;
    }

    .form {
        min-height: 650px;
    }

    &__left {
        flex-basis: 100%;

        @include mq($from: desktop) {
            border-right: 1px solid var(--gray-1);
            padding-right: 60px;
            height: 85%;

            h2 {
                margin-top: 0;
            }
        }

        &__inner {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .light {
                color: var(--gray-2);
            }

            // TODO remove this to other part of scss
            .social-icons {
                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 20px;
                }

                &:hover {
                    color: var(--blue);

                    svg {
                        fill: var(--blue);
                    }
                }
            }

            @include mq($from: desktop) {
                flex-direction: row;
                gap: 40px;
            }

            img {
                width: 200px;
                height: 300px;
                object-fit: cover;
                margin: 0 auto;
                border-radius: $border-radius;

                @include mq($from: desktop) {
                    margin: unset;
                }
            }
        }
    }

    &__right {
        flex-basis: 100%;

        @include mq($from: desktop) {
            padding-left: 60px;

            h3 {
                margin-top: 0;
            }
        }
    }
}

.hs-form {
    font-family: var(--font-Montserrat);
    padding: 0;

    @include mq($from: 970px) {
        display: grid;
        align-items: stretch;
        column-gap: 40px;
        row-gap: 5px;
        grid-template-columns: 1fr;
    }
}

.hs-error-msgs, .hs-error-msg {
    color: red;
}

.hs-form-field {
    label:not(.hs-error-msgs):not(.hs-error-msg) {
        margin: 1rem 0;
        display: block;
        font-weight: 500;
    }

    input, select, textarea {
        line-height: 54px;
        border-radius: 8px;
        border: solid 2px $gray-light;
        padding: 0 14px;
        font-family: var(--font-Montserrat);
        background: white;
    }

    input {
        height: 54px;
        width: 100%;
    }

    select {
        height: 54px;
        width: 100%;
    }

    textarea {
        min-height: 150px;
        width: 100%;
    }
}

.multi-container {
    list-style: none;
    padding: 0;

    li {
        display: inline;
        list-style-type: none;
        padding-right: 20px;
        float: left;
    }
}

.hs-input {
    line-height: 26px !important;
}
.hs-bold-input, .hs-bold-input::placeholder {
    @extend .hs-input;
    color: #1e1e1e;
    font-size: 18px;
    font-weight: 600;
}

.hs-bold-input::placeholder {
    color: #9fa1a3;
}

.hs-form-checkbox label {
    display: flex !important;
    align-items: center;
    margin-bottom: 0 !important;

    .hs-input {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }
}

select:focus,
textarea:focus,
input:focus {
    outline: none !important;
}

//Hubspot submit button
input[type="submit"] {
    background-image: linear-gradient(to right, var(--blue), var(--purple));
    font-weight: 800;
    color: var(--white);
}

.growth--form {
    width: 480px;
    padding: 64px 46px 56px 56px;
    margin: 0 0 10px 0; // Temporary

    background-color: #FFF;
    border-radius: $border-radius;

    h2 {
        margin: 0;
        font-size: 2rem;
    }

    .inner-label {
        position: relative;

        span {
            font-size: 14px;
            color: #9fa1a3;
            position: absolute;
            top: 15px;
            right: 20px;
        }
    }

    .img {
        width: 480px;
        height: 600px;
        margin: 0 0 40px -56px;
    }
}
