.events {

    &__section-top {
        div {
            display: grid;

        }

        div img {
            border-radius: 24px;
            max-width: 1160px;
            max-height: 624px;
            margin: auto;
        }
    }

    &__section-bottom {

        @include mq($from: 1100px) {
            display: grid;
            grid-template-columns: 0fr 10fr 2fr;

        }
        @include mq($from: 1240px) {
            display: grid;
            grid-template-columns: 0.5fr 9.5fr 2fr;

        }
        @include mq($from: 1350px) {
            display: grid;
            grid-template-columns: 1fr 9fr 2fr;

        }
        div {
            @include mq($from: 1100px) {
                grid-column-start: 2;
                grid-column-end: 3;

            }
            @include mq($from: 1240px) {
                grid-column-start: 2;
                grid-column-end: 3;

            }
            @include mq($from: 1350px) {
                grid-column-start: 2;
                grid-column-end: 3;

            }
        }

        &__grid {

            &-top {
                font-size: 20px;
                font-weight: 800;
            }

            &-bottom {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: space-between;
            }

        }
    }

}

