.pagination {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    list-style-type: none;
    padding: 0;

    @include mq($until: 1010px) {
       justify-content: space-between;
        margin: 0 24px;
    }

    a {
        text-decoration: none;
    }

    .page-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background: white;
        margin: 0 12px;

        &.active {
            background: #ee6723;
            color: white;
        }

    }

    .page-item-chevron {
        span {
            color: black;
            font-size: 25px;

            &:disabled {
                color: #D8D8D8;
            }
        }
    }


}

.mobile-hidden {
    @include mq($until: 1010px) {
        display: none;
    }
}

.desktop-hidden {
    @include mq($from: 1010px) {
        display: none;
    }
}
