.hero-image-text {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    background-image: url('https://placeimg.com/1920/1280/arch');
    background-position: center;
    background-size: cover;

    @include mq($from: 960px) {
        min-height: 75vh;
        max-height: 500px;
    }
    @include mq($from: 1152px) {
        padding: 0 0 120px 0;

    }

    h1 {
        margin: 0;
        padding: 0;
    }

    &__heading {
        max-width: 80vw;
        color: var(--white);
        margin: 10%;

        @include mq($from: 1150px) {
            margin: 0 10%;
        }

        .outlined {
            font-family: var(--font-Montserrat);
            letter-spacing: rem(1px);
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 3px;
            -webkit-text-stroke-color: white;
        }
    }
}
