
.header-image-container {
    margin-top: 48px;

    &__image {
        border-radius: $border-radius;
        object-fit: contain;
        object-position: top;
        width: 100%;
    }
}
.block-text-and-image {
    padding-top: 80px;
    background: url('/images/patterns/pattern-light@3x.png') no-repeat #F9F9F9 right;
    background-size: 60%;
}

.wysiwyg-container {
    margin: auto;
    max-width: 680px;

    a {
        color: $blue;

        &:hover {
            text-decoration: underline $blue;
        }
    }

    p {
        margin-top: 40px;
    }
}
