
.image-container, canvas {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100vh;
}

#pager-container {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
canvas#pager {
    position: absolute;
    top: 0; left: 0;
    z-index: 200;
    background: #191C20;

    transform-origin: 0 0;
    transform: skewY(14deg) translateY(90%) translateZ(0);
}
canvas#pager.open {
    animation: show .8s forwards;
}
canvas#pager.close {
    animation: hide .8s forwards;
}




canvas#stage {
    background-color: #191C20;
    z-index: 0;
    opacity: .5;
    transition: transform 2s ease-out;
}
canvas#stage.open {
    transform: translateY(-10%);
}

.image-container {
    margin: 0;
    overflow: hidden;
    z-index: 0;
    padding: 0
}

.image-container figure {
    margin: 0;
}

.image-container .wp-image {
    width: 100%;
    height: 100vw;
    object-fit: cover;
    object-position: center;
    opacity: 0;
}

/** end of GL **/
