.webgl {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.home {
    .navigation-top-bar {
        background-image: linear-gradient(to bottom, #0e141a, rgba(14, 20, 26, 0));
        background-color: transparent;
    }
}

@keyframes move-chevron {
    25% {
        opacity: 1;
    }
    33.3% {
        opacity: 1;
        transform: translateY(.6rem * 3.8);
    }
    66.6% {
        opacity: 1;
        transform: translateY(.6rem * 5.2);
    }
    100% {
        opacity: 0;
        transform: translateY(.6rem * 8) scale(0.5);
    }
}

.home {
    &--webgl-wrapper {
        position: relative;
        overflow: hidden;
    }

    // Intro section
    &--intro {
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: -80px;
        overflow: hidden;
        max-width: 100%;
        position: relative;

        &--wrapper {
            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            color: var(--white);
            margin-top: 150px;

            &--btns {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 30px;
                margin-top: 75px;

                @include mq($from: tablet) {
                    flex-direction: row;
                }

                a {
                    color: var(--white);
                }
            }

            &--button-animation-down {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 100px;
                cursor: pointer;

                .chevron {
                    position: absolute;
                    height: 16px;
                    width: 16px;
                    opacity: 0;
                    transform: scale(0.3);
                    animation: move-chevron 3s ease-out infinite;

                    &:first-child {
                        animation: move-chevron 3s ease-out 1s infinite;
                    }

                    &.chevron:nth-child(2) {
                        animation: move-chevron 3s ease-out 2s infinite;
                    }

                    svg {
                        color: white;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }

    // Swiper section
    &--swiper {
        .swiper {
            width: 100%;
            height: 92vh;
            overflow: visible;

            h1, h2, h3, p {
                color: var(--white);
            }

            a {
                color: var(--gray-2);


                &.active {
                    color: var(--white);
                }

                &:hover {
                    color: var(--white);
                }
            }
        }

        .swiper-wrapper {
            background-size: 844px 252px;
            background-repeat: no-repeat;
            background-position: top 45% right 10px;
        }

        .swiper-slide {
            background: transparent;
            display: flex;
            flex-direction: row;

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;

                h1 {
                    margin-top: 0;
                }

                h3 {
                    margin-bottom: 8px;
                }

                @include mq($from: tablet) {
                    max-width: 35%;
                }
            }

            img {
                width: 500px;
                height: 500px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        .animation-images {
            position: absolute;
            width: 100%;
            left: 65%;
            top: 50px;
            height: 240px;
            z-index: 0;

            &-img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                position: absolute;
                filter: blur(3px);
                object-position: left;
                transition: 1s ease-in-out;
                z-index: 1;

                &.square {
                    width: 100px;
                    height: 100px;
                    transform: translate3d(-100px, -20px, 0) rotate(10deg);
                }

                &.circle {
                    width: 61px;
                    height: 61px;
                    transform: translate3d(-50px, 20px, 0) rotate(0deg);
                }

                &.triangle {
                    width: 82px;
                    height: 82px;
                    transform: translate3d(0, 40px, 0) rotate(3deg);
                }

                &.dash {
                    width: 88px;
                    height: 88px;
                    transform: translate3d(70px, 40px, 0) rotate(-9deg);
                }

                &.active {
                    filter: none;
                    z-index: 3;
                    width: 242px;
                    height: 287px;
                    transform: translate3d(-50px, -25px, 1px) scale(1, 1) rotate(30deg);
                }
            }

            @include mq($from: tablet) {
                width: 65%;
                height: 60%;
                right: 0;
                left: 50%;
                top: 50%;

                &-img {
                    filter: blur(6px);
                    object-fit: cover;

                    &.square {
                        width: 185px;
                        height: auto;
                        transform: translate3d(-350px, 50%, 0);
                    }

                    &.circle {
                        width: 153px;
                        height: auto;
                        transform: translate3d(-100px, -185px, 0);
                    }

                    &.triangle {
                        width: 158px;
                        height: auto;
                        transform: translate3d(280px, -220px, 0);
                    }

                    &.dash {
                        width: 180px;
                        height: auto;
                        transform: translate3d(0, 125px, 0);
                    }

                    &.active {
                        transform: translate3d(100px, 0, 1px) scale(2.5, 2.5) rotate(-15deg);
                    }
                }
            }
        }

        .custom-swiper-pagination {
            position: absolute;
            bottom: 70px;
            transition: .3s ease-in-out;
            left: 25%;
            text-transform: uppercase;
            cursor: pointer;
            white-space: nowrap;
            transform: var(--transform-pagination, none);
            z-index: 10;

            @include mq($from: tablet) {
                bottom: 0;
            }

            @include mq($from: desktop) {
                overflow-x: unset;
                left: unset;
                min-width: unset;
                display: flex;
                align-items: center;
                gap: 30px;
                width: 100%;
                transform: unset;
            }

            button {
                margin-right: auto;
                background-color: transparent;
                border: 0;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 11.25px;
                font-weight: bold;
                color: $gray;
                transition: .3s ease-in-out;
                padding-bottom: 22px;
                width: 200px;
                z-index: 10;
                position: relative;
                text-align: left;

                @include mq($from: desktop) {
                    text-align: center;
                    width: 100%;
                }

                &:hover {
                    color: var(--white);
                    cursor: pointer;
                }

                &.active {
                    color: var(--white);
                }
            }
        }

        .swiper-pagination {
            position: absolute;
            bottom: 140px;
            left: 10px;
            right: 10px;
            width: auto !important;
            margin: 0;
            top: unset !important;
            background-color: #57626c;
            height: 2px;

            @include mq($from: tablet) {
                bottom: 60px;
            }
        }

        .swiper-pagination-progressbar-fill {
            background-color: var(--white) !important;
        }
    }

    // Our clients section
    &--our-clients {
        background-color: var(--black);

        h1 {
            color: var(--white);
            text-align: center;
        }

        .partners-slider {
            .swiper-wrapper {
                align-items: center;
                z-index: 0;
            }

            img {
                max-width: 155px;
                height: 85px;
                object-fit: contain;
            }

            @include mq($from: tablet) {
                &:last-of-type {
                    margin-left: -60px;
                }
            }
        }
    }

    // Cases section
    &--cases-section {
        position: relative;
        padding: 20px 0;

        @include mq($from: desktop) {
            padding: 100px 0;
        }

        &--image {
            border-radius: 24px;
            width: 1017px;
            height: 561px;
            object-fit: cover;
            position: absolute;
            z-index: 0;
            rotate: 5deg;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            opacity: 0;
            transition: .5s ease-in-out;
        }

        &--wrapper {
            display: grid;
            grid-template-columns: 1fr;
            gap: 30px;
            z-index: 2;

            @include mq($from: desktop) {
                grid-template-columns: 1fr 1fr;
                max-width: 750px;
                gap: 120px;
                margin: 60px auto;
            }

            a {
                display: flex;
                flex-direction: row;
                align-items: start;
                cursor: pointer;
                border-bottom: 1px solid var(--white);
                padding-bottom: 20px;
                gap: 10px;

                h1, h3 {
                    z-index: 2;
                }

                h3 {
                    font-size: rem(28px);
                }

                * {
                    font-weight: 700;
                    font-family: var(--font-Montserrat);
                    text-transform: uppercase;
                    color: transparent;
                    -webkit-background-clip: text;
                    cursor: pointer;
                    letter-spacing: rem(1px);
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke-width: 2px;
                    -webkit-text-stroke-color: white;
                    margin: 0;
                    transition: .5s ease-in-out;
                }

                @include mq($from: desktop) {
                    padding-bottom: 6px;
                    gap: unset;

                    &:hover {
                        img {
                            opacity: .7;
                        }

                        * {
                            -webkit-text-fill-color: var(--white);
                            -webkit-text-stroke-width: unset;
                        }
                    }
                }
            }
        }
    }

    // Events section
    &--event-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        & > * {
            flex: 1;
        }

        & * {
            color: var(--white);
        }

        img {
            border-radius: 15px;
            object-fit: cover;
            width: 100%;
            height: 211px;
        }

        div {
            padding-top: 8px;
        }

        h1, h2 {
            margin-top: 40px;
        }

        p {
            margin: 32px 0 40px 0;
        }

        &.reverse-order {
            img {
                margin-top: 130px;
            }
        }

        @include mq($from: tablet) {
            img {
                height: 386px;
            }
        }

        @include mq($from: desktop) {
            margin-bottom: 160px;
            flex-direction: row;

            &.reverse-order {
                h1 {
                    margin-top: 0;
                }
            }

            div {
                h2 {
                    margin: unset;
                }
            }

            & > div {
                padding-right: 100px;
            }

            img {
                width: 594px;
                height: 386px;
            }
        }
    }
}

// Maybe make component of this part
.show-reel {
    position: relative;
    z-index: 1;
    text-align: center;
    rotate: 2deg;
    margin: 130px 0;

    h2 {
        font-size: 50px;
        line-height: 54px;
    }

    &__container {
        margin: 0 auto;
        text-align: center;
        border-radius: $border-radius;
        max-width: 1160px;

        @include mq($until: 400px) {
            max-width: 95%;
        }
        @include mq($from: 401px) {
            max-width: 80%;
        }

        &__video {
            padding: 56.25% 0 0 0;
            position: relative;
            border-radius: $border-radius;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
            border-radius: $border-radius;
        }
    }
}

.born-of-code-row {
    .left-pattern {
        position: absolute;
        left: 0;
        bottom: -207px;
        width: 520px;
        height: 300px;
        object-fit: cover;
        display: none;

        @include mq($from: desktop) {
            display: unset;
        }
    }

    .right-pattern {
        position: absolute;
        right: 0;
        top: -40px;
        height: 100px;
        width: 200px;
        object-fit: cover;

        @include mq($from: tablet) {
            width: 425px;
            height: 200px;
            top: -130px;
        }

        @include mq($from: desktop) {
            width: 856px;
            height: 300px;
        }
    }

    &--infinite-wrapper {
        display: none !important;

        @include mq($from: tablet) {
            display: unset !important;
        }
    }
}

// Multiple images that rotate section
.multiple-images-rotate {
    display: flex;
    align-content: center;
    position: relative;
    z-index: 10;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    margin: 63px 0;

    img {
        object-fit: contain;
        border-radius: 24px;
        height: fit-content;
        align-self: center;

        &:first-child {
            width: 75%;
            height: auto;
            transform: rotate(5deg);
            z-index: 1;
            margin-left: auto;
            margin-top: -50px;
        }

        &:last-child {
            transform: rotate(-10deg);
            margin-right: auto;
            width: 75%;
            height: auto;
        }
    }

    @include mq($from: desktop) {
        flex-wrap: unset;

        img {
            &:first-child {
                width: 40%;
                margin-right: -50px;
                margin-top: auto;
            }

            &:last-child {
                width: 60%;
                margin-right: unset;
            }
        }
    }
}

// Heading with component and with one image that rotates section
.heading-with-rotate-image {
    display: grid;
    grid-template-columns: 1fr;

    @include mq($from: desktop) {
        grid-template-columns: 1fr 1fr;

        .block-info-topleftcurve {
            margin-top: 80px;
        }
    }

    & * {
        color: var(--white);
    }

    &--wrapper {
        position: relative;
        width: 100%;
        display: none;

        img {
            width: auto;
            border-radius: 15px;
            object-fit: contain;
            height: 100%;
            transform: rotate(5deg);
            position: absolute;
            z-index: 10;
        }

        @include mq($from: desktop) {
            display: unset;
            height: 591px;
        }
    }
}


.eg-home-content-wrapper {
    background-color: var(--black);
    overflow: hidden;
    max-width: 100%;
    width: 100%;
}
