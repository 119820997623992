$breakpoint-1: 700px;

.block-who-where {
    position: relative;
    //background-image: url("/images/culture/haarlem.png");
    //background-size: contain;

    @include mq($from: $breakpoint-1) {
        height: 800px;
    }

    &__heading {
        font-size: 32px;
        font-weight: 800;
        text-align: center;
        color: white;
        margin-bottom: 64px;
        line-height: 1.5;

        @include mq($from: $breakpoint-1) {
            font-size: 60px;
        }
    }

    &__images {
        position: relative;
        width: 100%;
        height: 187px;
        max-width: 375px;
        margin: 0 auto;

        @include mq($from: $breakpoint-1) {
            position: absolute;
            top: 250px;
            left: 50%;
            right: 50%;
            max-width: 790px;
            transform: translate(-50%);
            height: auto;
        }
        &__image {
            border-radius: 24px;

            &.image-1 {
                position: absolute;
                top: 0;
                left: 0;
                width: 117px;
                height: 117px;
                transform: rotate(-1deg);

                @include mq($from: $breakpoint-1) {
                    width: 258px;
                    height: 339px;
                    position: absolute;
                    top: 0px;
                    transform: rotate(8deg);
                }
            }

            &.image-2 {
                position: absolute;
                top: 21px;
                right: 20px;
                width: 122px;
                height: 144px;
                transform: rotate(8deg);

                @include mq($from: $breakpoint-1) {
                    width: 316px;
                    height: 373px;
                    position: absolute;
                    top: 408px;
                    right: 0;
                    transform: rotate(-8deg);
                }
            }

            &.image-3 {
                display: none;

                @include mq($from: $breakpoint-1) {
                    display: block;
                    width: 316px;
                    height: 316px;
                    position: absolute;
                    top: -95px;
                    left: -530px;
                    transform: rotate(-3deg);
                }
            }

            &.image-4 {
                display: none;

                @include mq($from: $breakpoint-1) {
                    display: block;
                    width: 208px;
                    height: 199px;
                    position: absolute;
                    top: -95px;
                    right: -586px;
                    transform: rotate(-2deg);
                }
            }
        }
    }

    &__content {
        max-width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column-reverse;

        @include mq($from: $breakpoint-1) {
            height: 100%;
            max-width: unset;
            flex-direction: row;
            justify-content: space-between;

            &__left {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                width: 50%;
                max-width: 440px;
            }

            &__right {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                width: 50%;
                max-width: 440px;
            }
        }

        p {
            color: white;
            font-size: 16px;
            font-weight: 800;

            @include mq($from: $breakpoint-1) {
                font-size: 32px;
                line-height: 1.25;
            }
        }
    }
}

