.header-topleft-curve {
    padding: 31px 0 36px 21px;
    position: relative;

    &:after {
        position: absolute;
        content: "";
        top: 0;
        width: 140px;
        left: 0;
        height: 107px;
        border-left: 2px solid #42C5D6;
        border-top: 2px solid #42C5D6;
        border-top-left-radius: 25px;
    }

    h1 {
        margin-top: 0;
        font-size: 40px;
        line-height: 44px;
        @include mq($from: 1448px) {
            font-size: 60px;
            line-height: 64px;
        }
    }
}
