.services-service {
    position: relative;
    z-index: 1;
    color: white;
    height: 1000px;
    background-repeat: no-repeat;
    background-position: top right;

    &__container {
        position: relative;
        z-index: 2;
        height: 1000px;

        &-backgrounds {
            position: relative;
            z-index: 2;
            height: 1000px;
            background-image: url("/images/patterns/texture_filled.png"),
            url("/images/patterns/shine@0.5x.png");
            background-repeat: no-repeat, no-repeat;
            background-position: top center, 0px 0px;
            background-size: auto, auto;
        }
    }

    h1 {
        padding: 10px 0;
        font-weight: 700;
    }

    p {
        line-height: 32px;
        width: 100%;
        max-width: 559px;
    }
}

.service {
    padding-top: 200px;
    position: relative;
    max-width: 800px;
    margin-left: 50px;

    &__number {
        position: absolute;
        left: -40px;
        top: 220px;
        font-weight: 800;
        font-size: 28px;
    }
    &__inner {

    }
}

.horizontal-scrolling--animated-title {
    position: absolute;
    bottom: 300px;
    left: 0;
    right: 0;
    z-index: 0;
}

@include mq($from: 768px) {
    .service-category {
        display: flex;
        justify-content: space-between;
        align-content: center;
        height: 409px;
    }

}
