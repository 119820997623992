.block-core-values {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @include mq($from: tablet) {
        gap: 40px;
    }

    @include mq($from: desktop) {
        gap: 175px;
        flex-wrap: nowrap;
    }

    .block-core-values_image-text-container {
        position: relative;
        display: flex;

        @include mq($from: desktop) {
            margin-left: 2rem;
        }
    }

    &__headers {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-right: 210px;

        @include mq($from: desktop) {
            margin-right: -15px;
        }

        &__value {
            position: relative;
            margin: 0 0 16px 0;
            font-weight: 800;
            font-size: rem(40px);
            font-family: var(--font-Montserrat);
            letter-spacing: rem(.8px);
            color: white;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: white;
            z-index: 1;
            cursor: pointer;

            @include mq($from: desktop) {
                font-size: rem(80px);
            }

            &.active {
                left: -20px;
                color: var(--white);
                -webkit-text-fill-color: var(--white);
                -webkit-text-stroke-width: 0;
                z-index: 3;
            }
        }
    }

    &__images {
        position: relative;

        &__image {
            display: none;
            position: absolute;
            top: -40px;
            left: -320px;
            width: 256px;
            height: 345px;
            border-radius: 25px;
            z-index: 2;
            transform: rotate(-4deg);
            object-fit: cover;
            object-position: center center;

            @include mq($from: desktop) {
                width: 440px;
                height: 572px;
            }

            &.active {
                display: unset;
            }
        }
    }

    .block-core-values__content-container {
        display: flex;
        overflow: hidden;
        align-self: flex-end;
        margin-top: 120px;

        @include mq($from: tablet) {
            margin-top: unset;
        }
    }

    &__content {
        p {
            color: var(--white);
            font-size: rem(16px);
            font-weight: 800;
            max-width: 240px;
            min-width: 240px;
            margin-right: 24px;
            line-height: 1.25;
            display: none;

            &.active {
                display: block;
            }

            @include mq($from: tablet) {
                font-size: rem(32px);
                max-width: 440px;
                margin-right: 80px;
            }
        }

        &.depressed {
            p {
                position: absolute;
                opacity: .1;
                width: 100%;

                &.active {
                    display: block;
                }
            }
        }
    }
}
