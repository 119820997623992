.services-header {
    position: relative;
    max-width: 780px;

    &:after {
        position: absolute;
        content: "";
        top: 0;
        width: 140px;
        left: 0;
        height: 161px;
        border-left: 2px solid #42C5D6;
        border-top: 2px solid #42C5D6;
        border-top-left-radius: 25px;
    }
    @include mq ($from: 768px) {
        z-index: 1;
    }
    &__heading {
        //max-width: 560px;
        padding-left: 21px;
        padding-top: 32px;
        color: white;
        font-weight: 700;
        padding-bottom: 0;
    }
    &__subheading {
        font-size: 20px;
        padding-left: 21px;
        padding-top: 16px;
        line-height: 32px;
    }

    &__paragraph {
        max-width: 440px;
        padding-top: 0;
        padding-left: 21px;
        line-height: 32px;
        color: white;
    }
}
