.uikit {
    &-colours--grid {
        display: inline-flex;
        padding: 10px 0 40px 0;
        flex-wrap: wrap;
        gap: 30px;

        &--item {
            width: 200px;
            height: 200px;

            p {
                color: var(--black);
                text-align: center;
            }
        }
    }
}
