// ---------------------------------------------------------------------------------------------------------------------
//      Plugins imports
// ---------------------------------------------------------------------------------------------------------------------
@import "plugins/mq";

//https://swiperjs.com/
@import '../../node_modules/swiper/swiper';
@import '../../node_modules/swiper/swiper-bundle.min.css';

//https://npm.io/package/sass-rem
@import "sass-rem";
// ---------------------------------------------------------------------------------------------------------------------
//      Variables
// ---------------------------------------------------------------------------------------------------------------------
@import "settings/variables";
// ---------------------------------------------------------------------------------------------------------------------
//      General imports
// ---------------------------------------------------------------------------------------------------------------------
@import "settings/spacingUnits";
@import "settings/animations";
@import "general/ui-kit";
@import "general/buttons";
@import "general/forms";
@import "general/locomotive";
@import "general/general";
@import "general/helpers";
@import "general/typography";
// ---------------------------------------------------------------------------------------------------------------------
//      Components imports
// ---------------------------------------------------------------------------------------------------------------------
@import "components/containers";
@import "components/sections/shadow-layers";
@import "components/sections/heading-with-texture";
@import "components/navigation";
@import "components/pager";
@import "components/cards";
@import "components/blocks";
@import "components/block-core-values";
@import "components/block-who-where";
@import "components/block-culture-story";
@import "components/more-info";
@import "components/services-header";
@import "components/services-service";
@import "components/services-card";
@import "components/header-topleft-curve";
@import "components/footer";
@import "components/footer";
@import "components/page-header";
@import "components/hero";
@import "components/pagination";
@import "components/tabview";
@import "components/animated-titles";
@import "components/card-text-image-overlay";
@import "components/form";
@import "components/social-icons";

// ---------------------------------------------------------------------------------------------------------------------
//      Pages imports
// ---------------------------------------------------------------------------------------------------------------------
@import "pages/contact";
@import "pages/home";
@import "pages/home-new";
@import "pages/blog";
@import "pages/cases";
@import "pages/knowledge";
@import "pages/services";
@import "pages/knowledge";
@import "pages/business-units";
@import "pages/vacancies";
@import "pages/events";
@import "pages/thank-you";
@import "pages/culture";
@import "pages/service-in-between";
@import "pages/long-read";

// ---------------------------------------------------------------------------------------------------------------------
//      Outdated imports
// ---------------------------------------------------------------------------------------------------------------------
// TODO remove this file
// @import "old-css-file";
