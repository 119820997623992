.bg-gray-1 {
    background-color: var(--gray-1);
}

.bg-gray-2 {
    background-color: var(--gray-2);
}

.bg-gray-3 {
    background-color: var(--gray-3);

    p {
        color: var(--white);;
    }
}

.bg-black {
    background-color: var(--black);

    p {
        color: var(--white);;
    }
}

.bg-white {
    background-color: var(--white);
}

.bg-blue {
    background-color: var(--blue);
}

.bg-pink {
    background-color: var(--purple);
}

.bg-yellow {
    background-color: var(--yellow);
}

.bg-orange {
    background-color: var(--orange);
}

.gradient-blue {
    background-image: var(--gradient-blue)
}

.gradient-gray {
    background-image: var(--gradient-gray);
    border: 1px solid lightgray;
}

.white {
    color: var(--white);
    background-color: var(white);

    h1, h4 {
        color: var(--black);
    }
}

.text-center {
    text-align: center;
}

@include mq($until: desktop) {
    .reverse-order {
        .home--event-row--content {
            order: 2;
        }
    }
}
