.social-icons {
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 0;

    &.black {
        li {
            a {
                color: var(--black);
            }
        }
    }

    li {
        list-style: none;

        a:hover {
            border-bottom: none;
            color: var(--blue);
            transition: .3s;
        }

        svg {
            width: 30px;
            height: 30px;
        }
    }
}
