.horizontal-scrolling {
    &--animated-title {
        position: relative;
        z-index: 1;

        span:before {
            content: '';
            background-image: linear-gradient(to right, #0e141a, rgba(14, 20, 26, 0) 15%, rgba(14, 20, 26, 0) 67%, #0e141a);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        span:first-of-type {
            position: absolute;
            top: 50%;
        }
    }
}


.infinite-container {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    z-index: -1;
    opacity: 0.5;

    &.index-1 {
        z-index: 1;
    }
}

.infinite-wrapper {
    white-space: nowrap;
    width: auto;

    p {
        line-height: rem(28px);
    }
}
.infinite-item {
    text-transform: uppercase;
}

.items-block {
    display: inline-block;
    white-space: nowrap;

    &.large {
        .infinite-item {
            font-size: rem(220px);
        }
    }

    &.small {
        .infinite-item {
            font-size: rem(110px);
        }
    }
}

.infinite-item {
    padding-right: 30px;
    padding-left: 30px;
    text-decoration: none;
    white-space: nowrap;
    display: flex;
    position: relative;
    font-size: rem(180px);
    font-weight: 700;
    font-family: var(--font-Montserrat);
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 4px var(--purple);
    -webkit-background-clip: text;
}

.infinite-item__solid {
    margin-left: 62px;
    background: -webkit-linear-gradient(236deg, #e925ee 5%, #00b4e5 95%);
    -webkit-text-stroke: 4px transparent;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
