
.thank-you {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq($from: 875px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__whitepaper-card {
        max-width: 480px;
        margin-top: 40px;

        @include mq($from: 875px) {
            margin-left: 80px;
            margin-top: 0;

        }
    }

}

