.vacancies {
    /* sections */
    &__header {
        background-image: url("/images/patterns/texture_full.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 280px;
        background-color: var(--black);

        h1 {
            background-color: var(--black);
            margin-bottom: 40px;
        }

        img {
            width: 268px;
            height: auto;
            object-fit: contain;
            margin: 0 auto;
            display: block;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        @include mq($from: 768px) {
            height: 668px;

            h1 {
                margin-bottom: 80px;
            }

            img {
                width: 716.9px;
                height: auto;
            }
        }
    }

    &__content {
        padding-top: 200px;
    }

    &__cards {
        margin-top: -180px;

        @include mq($until: tablet) {
            padding: 16px;
        }
    }

    .card-text-image-overlay__text {
        width: unset;
    }

    @include mq($until: tablet) {
        &__content {
            padding-top: 110px;
        }
    }

    .vacancy-text {
        padding: 0 24px;

        @include mq($until: 1500px) {
            padding: 0 24px;
        }

        p, span, strong, li {
            font-size: 18px;
            font-family: var(--font-Montserrat);
            line-height: 40px;
        }

        ul {
            padding: 0;
        }
    }

    .vacancy-card {
        height: fit-content;
        padding: 35px 35px 80px 35px;

        @include mq($from: tablet) {
            position: sticky;
            top: 100px;
        }

        h2 {
            margin-top: 40px;
            font-size: 20px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        &__share {
            display: block;
            border-top: 2px solid var(--gray-1);
            margin-top: 40px;

            h2:first-of-type {
                margin-top: 40px;
            }

            input {
                border: 1px solid var(--gray-1);
                padding: 7px 20px;
                width: 100%;
                border-radius: 8px;
                font-size: 16px;
                line-height: 40px;
                color: var(--gray-4);
                font-family: var(--font-Montserrat);
                position: relative;

                &:focus {
                    &:after {
                        position: absolute;
                        content: " ";
                        color: white;
                        background: black;
                        border-radius: $border-radius-btn;
                        right: 1rem;
                        top: 7px;
                        width: 10px;
                        height: 10px;
                    }
                }
            }

            .social-icons {
                margin-top: 30px;
                margin-bottom: 0;

                a:hover  {
                    color: var(--blue);

                    svg {
                        fill: var(--blue) !important;
                    }
                }
            }
        }

        &__icons {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            margin-bottom: 16px;

            h3 {
                margin: 0;
            }

            img {
                width: auto;
                object-fit: contain;
            }
        }

        p {
            margin: 0;
        }
    }

    .center-title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 120px;
        padding-bottom: 40px;

        @include mq($until: 768px) {
            padding-top: 16px;
            padding-bottom: 16px;

            & h1 {
                font-size: 24px !important;
            }
        }
    }

    .vacancy-bottom {
        display: grid;
        grid-template-columns: 1fr 30%;
        gap: 50px;

        @include mq($until: 768px) {
            display: flex;
            flex-direction: column;
        }
    }

    .gradient-background {
        background-image: linear-gradient(to right, #00b4e5 0%, #cf10d9 100%);
        background-size: 100% 60%;
        background-repeat: no-repeat;
    }

    &__card {
        display: block;
        padding-bottom: 20px;
        border-bottom: 2px solid $gray-light;

        &__heading {
            font-size: 32px;
            font-weight: 800;
            font-family: var(--font-Montserrat);
            line-height: 1.25;
        }

        &__sub-heading, &__content {
            font-size: 18px;
            font-weight: 600;
            line-height: 2.22;
            color: var(--gray-3);
        }

        &__content {
            font-weight: normal;
        }
    }

    .video-wrapper {
        position: relative;
        top: -275px;

        video {
            position: absolute;
            border-radius: 24px;
            top: 0;
            right: 0;
            width: 90%;
            left: 50%;
            transform: translateX(-50%);

            @include mq($from: tablet) {
                width: 680px;
                left: unset;
                transform: unset;
            }
        }
    }

    &__bottom-section {
        display: grid;
        grid-template-columns: 30% 1fr;
        grid-column-gap: 120px;

        @include mq($until: 768px) {
            display: flex;
            flex-direction: column;
        }

        &__column-right {

            h1 span {
                font-size: 20px !important;
            }
        }

        .card-info-white {
            padding: 35px;

            h3:first-of-type {
                margin-top: 0;
            }
        }
    }

    .php-form {
        margin-top: unset !important;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;

        h2 {
            margin: 0;
        }

        @include mq($from: tablet) {
            margin-bottom: unset;
        }
    }
}

