.tabview {
    &__list {
        list-style: none;
        display: flex;
        overflow-x: scroll;
        min-height: 25px;

        @include mq($from: tablet) {
            justify-content: center;
            overflow: unset;
        }

        &__item {
            margin-right: 24px;

            .item-link {
                text-decoration: none;
                font-weight: 600;
                color: $gray;
                white-space: nowrap;

                &:hover, &.active {
                    color: $edv-gray;
                }
            }
        }
    }
}
