.block {
    &-image-info,
    &-info,
    &-info-topleftcurve,
    &-header-outline {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &-image-info, &-image-info-horizontal {
        margin-bottom: 64px;

        @include mq($from: 960px) {
            max-width: 300px;
        }

        &__image {
            border-radius: $border-radius;
            width: 100%;
        }

        &__title {
            margin: 0 0 16px 0;
            line-height: 34px;
        }

        &__subtitle {
            font-size: 18px;
            line-height: 22px;
            margin: 24px 0;
        }

        &__description {
            line-height: 2;
            min-height: 3em;
        }

        .more-info {
            margin: 0 0 24px 0;
            padding: 24px 0;
        }
    }

    &-image-info-horizontal {
        @include mq($from: 960px) {
            max-width: 100%;
        }
        position: relative;
        display: flex;
        align-content: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        &__image {
            max-width: 440px;
            max-height: 266px;
        }

        &__textBlock {
            @include mq($from: 1000px) {
                max-width: 50%;
            }
            margin-right: 40px;
        }

        &__title {
            margin: 0;
        }
    }

    &-info {
        @include mq($from: 960px) {
            max-width: 300px;
        }

        &__title {
            margin: 24px 0;
            font-size: 30px;
            line-height: 34px;
        }

        &__subtitle {
            margin: 24px 0;
            font-size: 18px;
            line-height: 22px;
        }

        &__description {
            line-height: 2;
            min-height: 3em;
        }

        .more-info {
            margin: 24px 0;
        }
    }

    &-info-topleftcurve {
        max-width: 600px;

        &:after {
            position: absolute;
            content: "";
            top: 0;
            width: 140px;
            left: 0;
            height: 100px;
            border-left: 2px solid #42C5D6;
            border-top: 2px solid #42C5D6;
            border-top-left-radius: 25px;

            @include mq($from: 800px) {
                height: 161px;
            }
        }

        &--nocurve {
            display: flex;
            justify-content: center;
            height: 100%;

            &:after {
                content: none;
            }
        }

        &__title {
            margin: 24px;
            font-size: 40px;
            line-height: 44px;
            @include mq($from: 960px) {
                margin: 30px;
                font-size: 60px;
                line-height: 64px;
            }
        }

        &__subtitle {
            margin: 0 24px 24px 24px;

            @include mq($from: 960px) {
                margin: 0 30px 32px 30px;
            }
        }

        &__button {
            background-image: linear-gradient(to right, #00B4E5, #CF10D9);
            padding: $button-padding;
            border-radius: $border-radius-btn;
            border: none;
            cursor: pointer;
            transition: box-shadow 0.3s;
            color: white;
            text-decoration: none;
            margin-left: 30px;
            width: max-content;

            &:hover {
                box-shadow: 0 0 20px #cf10d9;
            }

            .hidden {
                display: none;
            }
        }

        &__description {
            margin: 0 24px 24px 24px;
            @include mq($from: 960px) {
                margin: 0 30px 30px 30px;
            }
            line-height: 2;
            min-height: 3em;
        }

        &__table {
            margin: 24px;
            @include mq($from: 960px) {
                margin: 40px 30px 0 30px;
            }
            font-family: 'Montserrat', sans-serif;
            line-height: 2;
            min-height: 3em;

            tr {
                vertical-align: top;
            }
            th {
                padding-right: 16px;
            }
        }

        .more-info {
            margin: 0 24px;
            @include mq($from: 960px) {
                margin: 0 30px;
            }
        }
    }

    &-header-outline {
        margin: 48px 0px;
        @include mq($from: 960px) {
            max-width: 300px;
            margin: 64px 24px 24px 24px;
        }

        h2 {
            font-size: 32px;
            margin: 0;
        }

        h3 {
            font-size: 32px;
            margin: 0;
            color: rgba(0, 0, 0, 0);
            -webkit-text-stroke: 1px #000;
        }

        &__description {
            font-size: 14px;
            line-height: 18px;
            margin-top: 14px;
            font-weight: bold;
        }
    }

    &-hero {
        position: relative;

        img {
            width: 100%;
        }

        &__title {
            position: absolute;
            bottom: 10%;
            left: 15%;
            color: white;
            @include mq($from: 960px) {
                max-width: 30%;
            }

            h2 {
                font-size: 80px;
                margin: 0;
                color: white;

                &.outlined {
                    font-family: 'MontserratOutlined', sans-serif !important;
                    letter-spacing: 1px;
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke-width: 2px;
                    -webkit-text-stroke-color: white;
                }
            }
        }

        &__card-text {
            position: absolute;
            bottom: 0;
            right: 15%;
            width: 40%;
            padding: 65px;
            background: white;
            border-radius: $border-radius $border-radius 0 0;

            h2 {
                font-size: 40px;
                line-height: 44px;
                margin-bottom: 24px;
            }

            &__source {
                font-size: $font-size;
            }
        }
    }

    &-info-centered {
        max-width: 680px;
        margin: 110px auto;
        text-align: center;

        &__title {
            font-weight: bold;
            font-size: 60px;
            line-height: 64px;
            margin: 0 0 24px 0;
        }

        &__description {

        }
    }

    &-image-large {
        max-width: 1160px;
        margin: 110px auto;
        text-align: center;
    }

    &-2-rounded-images {
        margin: 110px auto;
        display: flex;
        flex-direction: column;
        @include mq($from: 960px) {
            flex-direction: row;
            gap: 24px;
        }

        &__image {
            border-radius: 0 $border-radius $border-radius 0;

            img {
                border-radius: inherit;
                width: 100%;
            }

            &.reversed {
                border-radius: $border-radius 0 0 $border-radius;

                img {
                    border-radius: inherit;
                }
            }
        }
    }

    &-text-info-blocks {
        //background: red;
        max-width: 100%;
        grid-area: text-blocks;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //background: white;

        @include mq($from: 800px) {
            background: unset;
            flex-direction: row;
            padding-top: 111px;
            align-items: flex-start;
        }

        .block-info {
            max-width: unset;
            margin: 12px 0;
            width: 100%;
            @include mq($from: 1000px) {
                max-width: 300px;
                margin: 24px;
            }

            &__title {
                margin: 0 24px 24px 24px;
                @include mq($from: 960px) {
                    margin: 0 0 24px 0;
                    font-size: 20px;
                }
            }

            &__description {
                margin: 0 24px 24px 24px;
                @include mq($from: 960px) {
                    margin: 0 0 24px 0;
                }
            }
        }
    }

    &-case-cta {
        background-color: #f8f4f4;
        padding: 24px;
        border-radius: $border-radius;

        @include mq($from: 960px) {
            padding: 120px;
        }

        &__title {
            margin: 0;
            max-width: 530px;
            font-size: 32px;

            @include mq($from: 960px) {
                font-size: 60px;
            }
        }

        &__dots {
            color: #EE7B7B;
        }

        &__links {
            margin-top: 80px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            @include mq($from: 960px) {
                margin-left: 100px;
            }

            a:first-of-type {
                margin-right: 38px;
            }
        }
    }

    &-four-images-snippets {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        justify-content: space-around;


        &__image {
            height: 635px;
            max-width: 440px;
            min-width: 400px;
            width: 30%;
            background-size: cover;

            @include mq($until: 1226px) {
                margin-bottom: 2rem;
            }

            &:last-of-type {
                position: absolute;
                right: - 440px;
            }
        }
    }

    &-full-size-image {
        position: relative;
        width: 100vw;
        background-size: cover;
        background-position: center;
        height: 100vh;
        max-height: 1080px;


        &__title {
            color: white;
            font-weight: bold;
            font-size: 40px;
            line-height: 48px;


            @include mq($from: 750px) {
                //font-size: 60px;
                position: absolute;
                top: 200px;
                left: 100px;
                max-width: 50vw;

            }

        }


        &__container {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            height: 100%;
        }

        &__card {
            background: white;
            max-width: 920px;
            padding: 120px;
            position: relative;
            bottom: -5rem;
            box-shadow: $box-shadow;
            border-radius: $border-radius;

            @include mq($until: 960px) {
                margin-right: 3vw;
                padding: 20px;
            }

            &__description {
                font-size: 40px;
                line-height: 48px;
                margin-top: 0;

                @include mq($until: 960px) {
                    font-size: 20px;
                    line-height: 32px;
                }
            }

            &__sub-text {
                margin-top: 32px;
                font-weight: 600;
            }
        }
    }

    &-two-large-images {
        display: flex;
        justify-content: space-between;

        &__first, &__second {
            width: 50%;
            min-height: 220px;
            max-height: 940px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @include mq($from: 800px) {
                min-height: 440px;
            }

            @include mq($from: 1000px) {
                min-height: 800px;
            }
        }

        &__first {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            margin-right: 1rem;

            .full-rounded & {
                border-radius: $border-radius;
            }
        }

        &__second {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            margin-left: 1rem;

            .full-rounded & {
                border-radius: $border-radius;
            }
        }
    }

    &-four-images-text {
        display: flex;
        position: relative;
        justify-content: space-between;
        padding-bottom: 15rem;

        @include mq($from: 650px) {
            margin-left: 24px;
            margin-right: 24px;
        }

        @include mq($from: 1170px) {
            max-width: $max-width-small;
            margin: 0 auto;
            justify-content: space-evenly;
        }

        &__seperator {
            width: 40px;
        }

        &__first {
            position: absolute;
            left: -567px;
        }

        &__last {
            position: absolute;
            right: -567px;
        }

        &__card {
            background: white;
            border-radius: $border-radius;
            padding: 40px 24px;
            margin-left: 24px;
            margin-right: 24px;
            position: absolute;
            bottom: 0;

            @include mq($from: 650px) {
                margin-left: 60px;
                margin-right: 60px;
            }

            @include mq($from: 1010px) {
                margin-left: 120px;
                margin-right: 120px;
                padding: 120px;
            }
        }

        &__title {
            font-size: 32px;
            text-align: center;

            @include mq($from: 650px) {
                font-size: 60px;
            }
        }

        &__description {
            text-align: center;
        }

        .image {
            border-radius: $border-radius;
            max-height: 517px;
            max-width: 517px;

            @include mq($until: 650px) {
                border-radius: 0;
            }
        }


    }

    &-detached-image-with-text {
        position: relative;

        &-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;

            .reversed & {
                flex-direction: row-reverse;
            }

            @include mq($until: 1360px) {
                justify-content: center;
            }
        }

        &__detached_image {
            width: 50vw;
            height: 100%;
            position: absolute;
            left: -15px;
            top: 0;
            display: none;
            background-size: cover;
            background-position: center;
            //border-top-right-radius: $border-radius;
            //border-bottom-right-radius: $border-radius;
            border-radius: 24px;

            .reversed & {
                left: unset;
                right: -15px;
                //border-top-left-radius: $border-radius;
                //border-bottom-left-radius: $border-radius;
                border-radius: 24px;
            }

            @include mq($from: 1360px) {
                display: unset;
            }
        }

        .filler {
            max-width: 750px;
            width: 100%;

            &__image {
                max-width: 750px;
                border-radius: $border-radius;

                @include mq($from: 1360px) {
                    opacity: 0;
                }
                @include mq($from: 1500px) {
                    width: 50%
                }
            }
        }

        &__text {
            display: flex;
            align-items: center;
            max-width: 560px;

            @include mq($until: 1360px) {
                margin: 40px 24px 0 24px;
            }
            @include mq($from: 1360px) {
                margin: 40px 50px 0 24px;
            }
        }
    }

    &-full-horizontal-detail-and-image {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;

        .block-info-topleftcurve {
            max-width: 100%;
            @include mq($from: 1200px) {
                max-width: 600px;
            }

            .block-info-topleftcurve__title {
                max-width: 575px;
            }
        }

        &__detail {
            flex-basis: 50%;
            flex-grow: 0;
            max-width: 100%;
            @include mq($from: 1200px) {
                max-width: 600px;
            }

            .reversed & {
                margin-top: 4rem;
                @include mq($from: 1048px) {
                    margin-top: 0;
                }
            }
        }

        &__single-image, &__multiple-images {
            flex-basis: 50%;
            flex-grow: 0;
            margin-top: 4rem;
            @include mq($from: 1200px) {
                max-width: 600px;
            }
        }

        @include mq($from: 1048px) {
            flex-direction: row;

            &__single-image, &__multiple-images {
                margin-top: 0;

                img {
                    object-fit: contain;
                    object-position: top;
                }
            }

            &__detail {
                display: block;
            }
        }

        &.reversed {
            //justify-content: flex-end;
            flex-direction: column-reverse;
            @include mq($from: 1048px) {
                //justify-content: space-between;
                flex-direction: row-reverse;

                &__detail {
                    background: red;
                }
            }
        }

        &__single-image {
            width: 100%;

            img {
                max-width: 100%;
                border-radius: $border-radius;
            }
        }

        &__multiple-images {
            position: relative;
            width: 100%;
            padding-bottom: 95px;

            img {
                z-index: 1;
                border-radius: $border-radius;
                max-width: 80%;

                &:last-of-type {
                    position: absolute;
                    top: 95px;
                    right: 0;
                    z-index: 0;
                }
            }
        }
    }

    //culture page

    &-culture-image-text {
        position: relative;
        color: white;

        .image-text-wrapper {
            position: relative;
            top: -7rem;

            @include mq($from: 1024px) {
                top: -7rem;
            }
        }

        h2 {
            font-size: 48px;
            margin: 0;
        }

        h3 {
            font-size: 24px;
            margin-bottom: 16px;

            @include mq($from: 768px) {
                margin: 0;
            }
        }

        p {
            font-size: 16px;
        }

        &__top {
            height: 80px;
            background: linear-gradient(to right, #00b4e5 0%, #cf10d9 100%);
        }

        &__left {
            position: relative;
            z-index: 1;
            max-width: 500px;
            margin-top: 2rem;
            top: -1.5rem;

            @include mq($from: 768px) {
                position: absolute;
                top: unset;
                left: 74px;
                right: 24px;
                bottom: 0px;
            }

            @include mq($from: 1024px) {
                position: absolute;
                left: 90px;
                bottom: 50px;
            }
            @include mq($from: 1440px) {
                left: 260px;
                bottom: 25%;
            }

        }

        &__right {
            position: absolute;
            top: 40px;
            right: 0;
            margin-left: 48px;


            @include mq($from: 768px) {
                left: 74px;
                right: 24px
            }

            @include mq($from: 1024px) {
                right: 79px;
                left: 150px;
            }

            @include mq($from: 1440px) {
                right: 79px;
                left: 400px;
                top: 79px;
            }

            img {
                border-radius: 24px;

            }
        }
    }
}
