#header {
    z-index: 1;
    position: relative;
}

.video-bg {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &__heading {
        position: absolute;
        top: 55px;
        bottom: 0;
        z-index: 1;
        max-width: 1400px;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mq($from: 800px) {
            padding: 32px;
            bottom: 10%;
            top: unset;
            display: block;
        }

        @include mq($from: 1100px) {
            padding: 48px;
            left: 10%;
            bottom: 15%;
        }

        h1 {
            color: white;
            line-height: 1;
            text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
            font-size: 16px;

            @include mq($from: 500px) {
                font-size: 28px;
            }
            @include mq($from: 800px) {
                font-size: 44px;
            }
            @include mq($from: 1100px) {
                font-size: 60px;
            }
        }

        &__button {
            width: 260px;
            background-image: linear-gradient(to right, #00B4E5, #CF10D9);
            padding: $button-padding;
            border-radius: $border-radius-btn;
            border: none;
            cursor: pointer;
            transition: box-shadow 0.3s;
            text-align: center;

            @include mq($from: 768px) {
                margin: 64px 0;
            }

            a {
                text-decoration: none;
                color: white;
                font-family: var(--font-Montserrat);
                font-weight: 800;

                ::first-letter {
                    text-transform: capitalize;
                }
            }
        }
    }
}

/** show reel **/


/** portfolio **/
.home-client-list {
    z-index: 0;
    padding-top: 200px;

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-width: 1400px;
        overflow: hidden;

        .client {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 140px;
            height: 140px;
            padding: 0 2rem;

        }

        &__row1 {
            display: flex;
            justify-content: flex-start;
            width: 100%;

            @include mq($until: 620px) {
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        &__row2 {
            display: flex;
            justify-content: center;
            width: 100%;
            @include mq($until: 620px) {
                display: none;
            }
        }

        &__row3 {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            @include mq($until: 620px) {
                display: none;
            }
        }
    }
}

.campus {
    background: white;
    padding-top: 80px;
    padding-bottom: 100px;

    .block-header-outline__title {
        font-size: 32px;
        line-height: 54px;
        word-break: break-word;

        @include mq($from: 1100px) {
            font-size: 40px;
        }
    }

    &__container {
        &__details {
            @include mq($from: 1100px) {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
            }
        }
    }
}
