.card-text-image-overlay {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;

    @include mq($until: 768px) {
        flex-direction: column;
    }

    @include mq($from: tablet) {
        &__text {
            width: 50%;
        }
    }

    .text-card {
        max-width: 798px;
        z-index: 1;
        padding: 32px 24px;
        height: fit-content;
        width: 100%;

        @include mq($from: tablet) {
            padding: 61px 81px;
        }

        .title {
            width: 90%;
            font-size: 3.75rem;
            font-weight: 800;
            line-height: 1.07;
            color: var(--black);
            margin-top: 0;
        }

        .summary {
            &__item {
                display: flex;
                align-items: center;
                margin-top: 40px;
                font-size: 20px;
                font-weight: 800;
                line-height: 1.6;
                min-width: max-content;

                &:first-of-type {
                    margin-top: 0;
                }

                &:last-of-type {
                    margin-bottom: 32px;
                }
            }

            img {
                width: 32px;
                height: 32px;
                margin-right: 16px;
            }
        }

        .description {
            width: 90%;

            & p {
                font-size: 1.125rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.22;
                letter-spacing: normal;
                color: var(--black);
            }

        }
    }

    .apply-button-header {
        margin-left: 60px;
        margin-top: 60px;
        @include mq($until: 768px) {
            display: none;
        }
    }

    .center-mobile-button {
        display: flex;
        justify-content: center;
        align-items: center;

        .mobile-apply-button-header {
            margin-top: 40px;

            @include mq($from: 769px) {
                display: none;
            }
        }
    }


    .image-card {
        max-width: 680px;
        border-radius: 24px;
        box-shadow: rgb(17 17 26 / 10%) 0 4px 16px, rgb(17 17 26 / 5%) 0 8px 32px;
        z-index: 3;
        margin-left: -55px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 450px;
        margin-top: auto;
        margin-bottom: -60px;

        @include mq($until: 768px) {
            margin-top: -24px;
            margin-left: 0;
            max-width: 100%;
        }

        img, video {
            object-fit: cover;
            border-radius: 24px;
            width: 100%;
            height: 100%;
        }
    }
}


.basic-card {
    background: white;
    box-shadow: rgb(17 17 26 / 10%) 0 4px 16px, rgb(17 17 26 / 5%) 0 8px 32px;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
}
