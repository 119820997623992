.u-container {
    max-width: $max-width;
    margin: 0 auto;
    padding-left: $padding;
    padding-right: $padding;

    @include mq($from: 1424px) {
        padding: 0;
    }

    &--small {
        max-width: $max-width-small;
    }

    &--tiny {
        max-width: $max-width-tiny;
    }
}
