.services-card-container {
    border-radius: $border-radius;
    padding: 32px 30px;
    margin: 32px 0;
    background: white;

    @include mq($from: 768px) {
        max-width: 680px;
        padding: 85px 79px;
        margin: 40px 0;
    }

    p {
        padding: 24px 0;
        @include mq($from: 768px) {
           font-size: 18px;
        }

    }
    @include mq ($from: 768px) {
        &:hover {
            background: linear-gradient(90deg, #00B4E5, #CF10D9, #00B4E5, #CF10D9);
            background-size: 800% 100%;
            animation: gradient 10s ease forwards infinite;

            h1, h2, .section-heading, p, .see-more-link, svg path {
                color: white;
                fill: white;
            }
        }
    }
}
