.service-in-between-page-header {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 15rem;
    padding: 2rem;
    line-height: 2em;

    @include mq($from: 1024px) {
       margin-bottom: 55px;
    }

    &--title {
        font-size: clamp(2.5rem, 8vw, 3.75rem);
    }

    &--description {
        left: 24px;
        top: 142px;
        max-width: 680px;

        @include mq($from: 1024px) {
            left: 90px;
            top: 200px;
        }

        @include mq($from: 1440px) {
            left: 260px;
            top: 329px;
        }
    }
}
