.services {

    &.index {
        background-color: var(--gray-4);
        background-image: url("/images/services/background-top.webp");
        background-repeat: no-repeat;
        background-position: top right;
    }

    &__top {
        background-image: url("/images/services/index/Shapes.png");
        background-repeat: no-repeat;
        background-position: top right 300px;
        @include mq($until: $mobile-view-width) {
            background-position: top right;
        }
        height: 920px;
    }

    &__list {

    }

    .background-text {
        width: 1541px;
        height: 218px;
        border-style: solid;
        border-width: 2px;
        border-image-source: linear-gradient(98deg, #3023ae, #c86dd7 102%);
        border-image-slice: 1;
        background-color: rgba(255, 255, 255, 0);
    }
    .video-container {
        margin-top: 55px;
        margin-bottom: 55px;

        @include mq($from: 1000px) {
            margin-bottom: 111px;
        }
    }

    .text-large-image {
        &__text {
            margin: 48px 24px;
            padding-bottom: 20px;
        }
    }

    .float-boxes {
        max-width: 100%;
        margin: 0 auto;
        position: relative;
        top: -80px;
        display: grid;
        grid-template-areas:"card-white"
                            "text-blocks"
                            "card-blue";
        @include mq($from: $mobile-view-width) {
            grid-template-rows: auto auto;
            grid-template-columns: 1fr 1fr;
            grid-template-areas:"card-white card-blue"
                                "text-blocks text-blocks";
        }

        &__card-white {
            position: relative;
            grid-area: card-white;
            align-items: flex-start;
            display: grid;
            @include mq($from: $mobile-view-width) {
                justify-content: flex-end;
            }

            .card-info-white {
                margin: 0;
                box-shadow: unset;
                border-radius: $border-radius $border-radius 0 0;
                width: 100%;

                @include mq($from: $mobile-view-width) {
                    max-width: 680px;
                    position: relative;
                    right: -9px;
                    top: 26px;
                    box-shadow: $box-shadow;
                    border-radius: $border-radius;
                }
            }
        }

        &__card-blue {
            display: grid;
            grid-area: card-blue;
            background: white;
            //justify-content: center;

            @include mq($from: $mobile-view-width) {
                background: unset;
                justify-content: flex-start;
            }

            .card-info-blue {
                @include mq($from: $mobile-view-width) {
                    max-width: 480px;
                    margin: 64px 24px 0 79px;
                }
            }
        }

        &__text-blocks {
            max-width: 100%;
            grid-area: text-blocks;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: white;

            @include mq($from: $mobile-view-width) {
                background: unset;
                flex-direction: row;
                padding-top: 111px;
                align-items: flex-start;
            }

            .block-info {
                max-width: unset;
                margin: 12px 0;
                width: 100%;
                @include mq($from: $mobile-view-width) {
                    max-width: 300px;
                    margin: 24px;
                }

                &__title {
                    margin: 0 24px 24px 24px;
                    @include mq($from: $mobile-view-width) {
                        margin: 0 0 24px 0;
                    }
                }

                &__description {
                    margin: 0 24px 24px 24px;
                    @include mq($from: $mobile-view-width) {
                        margin: 0 0 24px 0;
                    }
                }
            }
        }
    }

    .cases-section {
        margin-top: 40px;
        @include mq($from: 768px) {
            margin-top: 40px;
        }

        &__title {
            @include mq($from: 768px) {
                margin: 0 auto;
            }
        }

        &__cards {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            @include mq($from: 768px) {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: normal;
                justify-content: space-between;
                margin-bottom: 154px;
                margin-top: 80px;

            }
        }


    }

    .insights-section {
        padding: 0;

        &__title {
            text-align: center;
            padding-bottom: 20px;
            @include mq($from: 960px) {
                padding-bottom: 80px;
            }
        }

        &__cards {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include mq($from: 768px) {
                flex-direction: row;
                align-items: flex-start;
                flex-wrap: wrap;
            }

            .block-image-info {
                margin: 48px;
                @include mq($from: 960px) {
                    margin: 24px;
                }
            }
        }
    }

    /* temp untill moved to cases.scss */
    .text-blocks {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        @include mq($from: 1000px) {
            background: unset;
            flex-direction: row;
            padding-top: 111px;
            padding-bottom: 111px;
            align-items: flex-start;
        }

        .block-info {
            max-width: unset;
            margin: 10px 0;

            &__title {
                margin: 0 48px;
            }
        }
    }
}
